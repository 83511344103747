@font-face {
  font-family:'it-icons';
  src:url('../assets/icons/it-icons.ttf');
  src:url('../assets/icons/it-icons.eot?#iefix') format('embedded-opentype'),
  url('../assets/icons/it-icons.woff') format('woff'),
  url('../assets/icons/it-icons.ttf') format('truetype'),
  url('../assets/icons/it-icons.svg#icomoon') format('svg');
}

// @import "variables";

.it-icon {
  font-family: it-icons, sans-serif !important;

  // Navigation
  &.menu {
    &:before {
      content: '\e901';
    }
  }
  &.menu-close {
    &::before {
      content: '\e900';
    }
  }
  &.minus {
    &:before {
      content: '\e902';
    }
  }
  &.plus {
    &:before {
      content: '\e903';
    }
  }
  &.search {
    &:before {
      content: '\e904';
    }
  }

  // Slider Arrows
  &.arrow-slider-left {
    &:before {
      content: '\e90b';
    }
  }
  &.arrow-slider-right {
    &:before {
      content: '\e90c';
    }
  }
  &.arrow-slider-gray-left {
    &:before {
      content: '\e907';
    }
  }
  &.arrow-slider-gray-right {
    &:before {
      content: '\e909';
    }
  }

}
