@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-Bold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-Black.ttf');
  font-weight: 800;
}

@font-face {
  font-family: Prompt;
  src: url("../assets/fonts/Prompt/Prompt-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Prompt;
  src: url('../assets/fonts/Prompt/Prompt-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Prompt;
  src: url('../assets/fonts/Prompt/Prompt-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Prompt;
  src: url('../assets/fonts/Prompt/Prompt-Bold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Prompt;
  src: url('../assets/fonts/Prompt/Prompt-Black.ttf');
  font-weight: 800;
}

@font-face {
  font-family: Oswald;
  src: url('../assets/fonts/Oswald/Oswald-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Oswald;
  src: url('../assets/fonts/Oswald/Oswald-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Rubik;
  src: url('../assets/fonts/Rubik/Rubik-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Rubik;
  src: url('../assets/fonts/Rubik/Rubik-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Rubik;
  src: url('../assets/fonts/Rubik/Rubik-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Manrope;
  src: url('../assets/fonts/Manrope/Manrope-SemiBold.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Manrope;
  src: url('../assets/fonts/Manrope/Manrope-ExtraLight.ttf');
  font-weight: 200;
}

.it-font-prompt {
  font-family: Prompt, sans-serif;
}

.it-font-roboto {
  font-family: Roboto, sans-serif;
}
