@import "variables";

.it-toasts__wrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  position: fixed;
  z-index: 900901;
  &.top-left {
    top: 16px;
    left: 16px;
    &.slide {
      left: 0;
      it-toast {
        animation: slide-left .4s ease-in-out both;
      }
    }
    &.scale {
      it-toast {
        animation: scale-center .4s ease-in-out both;
      }
    }
    &.swing {
      it-toast {
        animation: swing-left .4s ease-in-out both;
      }
    }
    &.jelly {
      it-toast {
        animation: yelly .6s linear;
      }
    }
  }
  &.top-right {
    top: 16px;
    right: 16px;
    &.slide {
      right: 0;
      it-toast {
        animation: slide-right .4s ease-in-out both;
      }
    }
    &.scale {
      it-toast {
        animation: scale-center .4s ease-in-out both;
      }
    }
    &.swing {
      it-toast {
        animation: swing-right .4s ease-in-out both;
      }
    }
    &.jelly {
      it-toast {
        animation: jelly .6s linear;
      }
    }
  }
  &.bottom-left {
    bottom: 16px;
    left: 16px;
    &.slide {
      left: 0;
      it-toast {
        animation: slide-left .4s ease-in-out both;
      }
    }
    &.scale {
      it-toast {
        animation: scale-center .4s ease-in-out both;
      }
    }
    &.swing {
      it-toast {
        animation: swing-left .4s ease-in-out both;
      }
    }
    &.jelly {
      it-toast {
        animation: jelly .6s linear;
      }
    }
  }
  &.bottom-right {
    bottom: 16px;
    right: 16px;
    &.slide {
      right: 0;
      it-toast {
        animation: slide-right .4s ease-in-out both;
      }
    }
    &.scale {
      it-toast {
        animation: scale-center .4s ease-in-out both;
      }
    }
    &.swing {
      it-toast {
        animation: swing-right .4s ease-in-out both;
      }
    }
    &.jelly {
      it-toast {
        animation: jelly .6s linear;
      }
    }
  }
  &.top-full {
    top: 0;
    width: 100%;
    &.slide {
      it-toast {
        animation: slide-bottom .4s ease-in-out both;
      }
    }
    &.scale {
      it-toast {
        animation: scale-center .4s ease-in-out both;
      }
    }
    &.swing {
      it-toast {
        animation: swing-bottom .4s ease-in-out both;
      }
    }
    &.jelly {
      it-toast {
        animation: jelly .6s linear;
      }
    }
    .it-toast__wrapper {
      min-width: 95%;
      margin: 0 auto;
      border-radius: 0 0 8px 8px;
    }
  }
}
.it-toast__wrapper {
  background: #fff;
  padding: 12px;
  border-radius: $borderRadius;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  &.error {
    background: $secondary20;
  }
  &.warning {
    background: $warningLight;
  }
  &.success {
    background: #fff;
  }
}
.it-toast {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .it-toast__message {
    padding: 0 24px 0 0;
    font-size: 13px;
    font-weight: 500;
  }
  .it-toast__close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    &:before {
      content: '\e96a';
      font-family: $icons;
      font-size: 24px;
      color: $gray60;
    }
  }
}

@keyframes slide-bottom {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(-300px);
  }
  100% {
    transform: translateX(16px);
  }
}
@keyframes slide-right {
  0% {
    transform: translateX(300px);
  }
  100% {
    transform: translateX(-16px);
  }
}

@keyframes scale-center {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes swing-left{
  0%{
    transform: rotateY(-360deg);
    transform-origin: left
  }
  100%{
    transform: rotateY(0deg);
    transform-origin: left
  }
}
@keyframes swing-right{
  0%{
    transform: rotateY(0deg);
    transform-origin: right
  }
  100%{
    transform: rotateY(360deg);
    transform-origin: right
  }
}
@keyframes swing-bottom{
  0%{
    transform: rotateX(180deg);
    transform-origin: top;
  }
  100%{
    transform: rotateX(0deg);
    transform-origin: top
  }
}

@keyframes jelly {
  11.1% {
    transform: none
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg)
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg)
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg)
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg)
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg)
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg)
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg)
  }
  100% {
    transform: none
  }
}


