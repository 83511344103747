@import "variables";

.it-tooltip {
  position: absolute;
  max-width: 400px;
  color: #313131;
  padding: 16px 12px;
  background-color: #fff;
  border-radius: $borderRadius;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 9999999999;
  opacity: 0;

  p {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    color: $gray60;
    font-family: Prompt, sans-serif;
  }
}

.it-tooltip-show {
  opacity: 1;
}
