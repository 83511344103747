@font-face {
  font-family:'it-icons';
  src:url('../assets/fonts/icons/it-icons.ttf');
  src:url('../assets/fonts/icons/it-icons.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/icons/it-icons.woff') format('woff'),
  url('../assets/fonts/icons/it-icons.ttf') format('truetype'),
  url('../assets/fonts/icons/it-icons.svg#icomoon') format('svg');
}

@import "variables";

.it-icon {
  font-family: it-icons, sans-serif !important;

  // Auth
  &.logout {
    &:before {
      content: '\e95c';
    }
  }

  // Arrows
  &.arrow-down {
    &:before {
      content: '\e956';
    }
  }
  &.arrow-up {
    &:before {
      content: '\e95b';
    }
  }
  &.arrow-left {
    &:before {
      content: '\e959';
    }
  }
  &.arrow-right {
    &:before {
      content: '\e95a';
    }
  }

  // Navigation
  &.dashboard {
    &:before {
      content: '\e93c';
    }
  }
  &.menu {
    &:before {
      content: '\e940';
    }
  }
  &.pages {
    &:before {
      content: '\e942';
    }
  }
  &.pages {
    &:before {
      content: '\e942';
    }
  }
  &.products {
    &:before {
      content: '\e943';
    }
  }
  &.discounts {
    &:before {
      content: '\e93d';
    }
  }
  &.orders {
    &:before {
      content: '\e941';
    }
  }
  &.files {
    &:before {
      content: '\e93e';
    }
  }
  &.forms {
    &:before {
      content: '\e93f';
    }
  }
  &.users {
    &:before {
      content: '\e944';
    }
  }

  // Symbols
  &.add {
    &:before {
      content: '\e95d';
    }
  }
  &.more {
    &:before {
      content: '\e90d';
    }
  }
  &.delete {
    &:before {
      content: '\e960';
    }
  }
  &.block {
    &:before {
      content: '\e95e';
    }
  }
  &.edit {
    &:before {
      content: '\e962';
    }
  }
  &.required {
    &:before {
      content: '\e930';
    }
  }
  &.copy {
    &:before {
      content: '\e95f';
    }
  }
  &.move {
    &:before {
      content: '\e928';
    }
  }
  &.star {
    &:before {
      content: '\e967';
    }
  }
  &.star-filled {
    &:before {
      content: '\e968';
      color: $warning;
    }
    &.primary {
      &:before {
        color: $primary;
      }
    }
  }
  &.image {
    &:before {
      content: '\e949';
    }
  }
  &.upload {
    &:before {
      content: '\e96c';
    }
  }
  &.info {
    &:before {
      content: '\e92a';
    }
  }
  &.reload {
    &:before {
      content: '\e969';
    }
  }
  &.link {
    &:before {
      content: '\e963';
    }
  }
  &.grid {
    &:before {
      content: '\e915';
    }
  }
  &.loading {
    &:before {
      content: '\e90c';
    }
  }
  &.download {
    &:before {
      content: '\e961';
    }
  }
  &.failed {
    &:before {
      content: '\e970';
      color: $secondary;
    }
  }
  &.done {
    &:before {
      content: '\e96f';
      color: $success;
    }
  }
  &.up-level {
    &:before {
      content: '\e971';
    }
  }
  &.settings {
    &:before {
      content: '\e910';
    }
  }
  &.full-screen-enter {
    &:before {
      content: '\e909';
    }
  }
  &.full-screen-exit {
    &:before {
      content: '\e90a';
    }
  }
  &.block {
    &:before {
      content: '\e95e';
    }
  }
  &.date {
    &:before {
      content: '\e94f';
    }
  }

  //Section
  &.slider {
    &:before {
      content: '\e972';
    }
  }
  &.product-list {
    &:before {
      content: '\e973';
    }
  }
  &.newsletter {
    &:before {
      content: '\e974';
    }
  }
  &.custom-section {
    &:before {
      content: '\e975';
    }
  }
  &.categories {
    &:before {
      content: '\e976';
    }
  }
  &.all-products {
    &:before {
      content: '\e977';
    }
  }

  &.close {
    &:before {
      content: '\e96a';
    }
  }

  //File types
  &.pdf {
    &:before {
      content: '\e94c';
    }
  }
  //Social media
  &.facebook {
    &:before {
      content: '\e91a';
    }
  }
  &.pinterest {
    &:before {
      content: '\e91d';
    }
  }
  &.instagram {
    &:before {
      content: '\e91b';
    }
  }

}
