@import "variables";

it-select {
  position: relative;
}
.it-select-wrapper {
  display: flex;
  flex-direction: column;
  .it-select__error-box {
    margin-top: 4px;
    .it-select__error {
      &.single {
        span {
          color: $secondary;
          font-size: 13px;
          line-height: 16px;
        }
      }
      &.multi {
        span {
          cursor: pointer;
          line-height: 16px;
          color: $secondary;
          font-size: 13px;
        }
        ul {
          li {
            color: $secondary;
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.it-select {
  .it-select__control-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 22px;
    border: 1px solid $gray22;
    border-radius: 8px;
    background-color: #fff;
    padding: 17px 4px 17px 12px;
    color: $gray60;
    cursor: pointer;
    outline: none;
    transition: border .3s ease-out;
    overflow: hidden;
    &:focus {
      border-color: $primary;
      box-shadow: 0 0 0 1px $primary;
    }
    &:hover {
      .it-select__deselectAll {
        display: flex;
      }
    }
    &:after {
      content: '\e956';
      font-family: it-icons, sans-serif;
      font-size: 32px;
    }
    &.open {
      &:after {
        content: '\e95b';
      }
    }
    &.error {
      background-color: $secondary20;
      box-shadow: 0 0 0 1px $secondary;
      border-color: $secondary;
    }
    &.disabled {
      background-color: $gray10;
      border-color: $gray10;
    }
    &.readOnly {
      pointer-events: none;
      border: none;
      background-color: transparent;
      &:after {
        display: none;
      }
    }
    &.required-full {
      border-color: $primary;
      background: $primary20;
    }
    &.small {
      height: 16px;
    }
    .it-select__deselectAll {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 36px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $gray22;
      &:after {
        content: "\e96a";
        font-family: $icons;
        color: #fff;
        font-size: 12px;
      }
    }
    .it-select__value {
      font-size: 14px;
      color: $gray80;
      font-weight: 400;
    }
    .it-select__multiple-value {
      width: calc(100% - 64px);
      overflow: hidden;
      white-space: nowrap;
      .selected-option {
        display: inline-flex;
        height: 40px;
        align-items: center;
        font-size: 14px;
      }
    }
    .it-select__placeholder {
      display: inline-block;
      width: calc(100% - 60px);
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .it-select__list-container {
    position: absolute;
    overflow: hidden;
    max-height: 0;
  }
}

.it-select__label {
  position: relative;
  font-size: 13px;
  color: $gray60;
  font-weight: 500;
  margin-bottom: 8px;
  display: inline-block;
  .required {
    color: $secondary;
    margin-left: 4px;
    position: absolute;
    top: -4px;
  }
}

.event-disabled {
  pointer-events: none !important;
}

.it-select-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1070;
  .it-select__list-container {
    position: absolute;
    background: #fff;
    border-radius: 8px;
    margin-top: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    z-index: 9999999999;
    overflow: hidden;
    opacity: 0;
    max-height: 400px;
    transform: translateY(15px);
    transition: opacity .3s linear, transform .2s linear;
    pointer-events: none;
    &.it-show {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }
    &.clearing {
      opacity: 0;
    }
    .it-close__list {
      display: none;
      width: 100%;
      align-items: center;
      color: $gray80;
      font-size: 24px;
      padding: 8px 16px;
      .close {
        &:before {
          display: block;
          content: '\e96a';
          font-family: it-icons, sans-serif;
          font-size: 28px;
        }
      }
      .label {
        margin: 16px 0;
      }
    }
    .responsive-actions {
      display: none;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      width: 100%;
      padding: 8px 16px;
      .button-clear {
        opacity: 0;
        pointer-events: none;
        transition: opacity .15s;
      }
      .button-confirm {
        opacity: 0;
        pointer-events: none;
        transition: opacity .15s;
      }
    }
    .search {
      display: flex;
      input {
        height: 40px;
        width: 100%;
        padding: 0 12px;
        border: none;
        outline: none;
        font-size: 14px;
        color: $gray60;
        font-family: Rubik, sans-serif;
      }
    }
    .empty, .not-found {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 32px 0;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      color: $gray60;
      font-family: Prompt, sans-serif;
    }

    .it-select__options-container {
      overflow: auto;
      max-height: 240px !important;
      position: relative;
      padding: 0;
      margin: 0;
      list-style: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      scrollbar-color: $gray22 #fff;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 4px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 8px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 8px;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      ul {
        position: relative;
        :not(:first-child) {
          margin-top: 1px;
        }
      }
      li {
        padding: 8px 12px;
        cursor: pointer;
        .option-text {
          color: $gray80;
          font-weight: 400;
          font-size: 14px;
          cursor: pointer;
        }
        &:hover {
          background-color: $primary;
          input {
            &::before {
              color: transparent;
            }
          }
          .option-text {
            color: #fff;
            font-weight: 500;
          }
        }
      }
      .add {
        display: flex;
        justify-content: center;
        margin: 8px 12px;
        &:hover {
          background-color: transparent !important;
        }
        button {
          height: 32px;
          &:before {
            font-size: 16px;
          }
          span {
            line-height: normal;
            font-size: 13px;
            color: #fff;
          }
        }
      }
      .option {
        display: flex;
        align-items: center;
        label {
          display: flex;
        }
        input {
          height: 24px;
          margin: 0 20px 0 0;
          font-family: it-icons, sans-serif;
          &::before {
            content: '\e929';
            font-size: 24px;
            height: 24px;
            position: absolute;
            background-color: #fff;
            color: $gray22;
            border-radius: 8px;
          }
          &:checked {
            &::after {
              content: '\e927';
              font-size: 24px;
              height: 24px;
              position: absolute;
              color: $primary;
              border-radius: 8px;
            }
          }
        }
        &.checked {
          background-color: $primary20;
          &:hover {
            background-color: $primary;
          }
          .option-text {
            font-weight: 500;
          }
        }
        &.checked-dark {
          background-color: $primary;
          .option-text {
            color: #fff;
            font-weight: 500;
          }
        }
        .option-content {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-width: 660px){
  .it-select {
    .it-select__control-display {
      .it-select__deselectAll {
        display: flex;
      }
      &:hover {
        .it-select__deselectAll {
          display: flex;
        }
      }
    }
  }
  .it-select-overlay-container {
    .it-select__list-container {
      margin-top: 0;
      width: 100% !important;
      height: 100%;
      top: 0 !important;
      z-index: 30002 !important;
      left: 0;
      border-radius: 0;
      position: fixed;
      &.it-show {
        max-height: 100%;
      }
      .it-select__options-container {
        max-height: initial !important;
        height: 89%;
        padding-bottom: 32px;
        .option {
          border-bottom: 1px solid $gray60;
          padding: 16px;
          margin-top: 0;
          .option-text {
            font-size: 16px;
          }
        }
      }
      .it-close__list {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background-color: $primary;
        color: #fff;
      }
      .responsive-actions {
        display: flex;
        background: #fff;
        position: absolute;
        bottom: 0;
        padding: 24px 16px;
        .button-confirm {
          &.show {
            opacity: 1;
            pointer-events: all;
          }
          button {
            font-size: 16px;
            font-family: Prompt, sans-serif;
            color: $primary;
            border: none;
            background-color: transparent;
            outline: none;
          }
        }
        .button-clear {
          &.show {
            opacity: 1;
            pointer-events: all;
          }
          button {
            font-size: 16px;
            font-family: Prompt, sans-serif;
            color: $gray60;
            border: none;
            background-color: transparent;
            outline: none;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
