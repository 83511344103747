@import "variables";
@import "_modal";

.it-image {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 226px;
  width: 276px;
  background: transparent;
  border-color: $gray22;
  border-width: 1px;
  border-style: dashed;
  padding: 16px;
  margin-bottom: 12px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: border-color .15s ease-in-out;
  &:hover {
    border-color: $primary;
    border-width: 2px;
    border-style: solid;
    .it-image__icon, .it-image__label {
      color: $primary;
    }
  }
  &.readyToDrop {
    opacity: 0.6;
  }
  &.disabled {
    border: 1px dashed $gray22;
    cursor: not-allowed;
    .it-image__label, .it-image__icon {
      color: $gray22;
    }
  }
  &.large {
    height: 328px;
    width: 100%;
  }
  .it-image__label {
    font-size: 15px;
    color: $gray60;
    transition: color .2s ease-in-out;
  }
  .it-image__icon {
    color: $gray60;
    font-size: 46px;
    margin-bottom: 8px;
    opacity: 1;
    transition: color .2s ease-in-out;
  }
  .it-image__preview {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .3s linear;
  }
}
.it-image__actions {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  .it-icon {
    display: flex;
    align-items: center;
    font-size: 18px;
    &:first-child {
      margin-right: 24px;
    }
  }
  span {
    color: $gray60;
    font-size: 13px;
    font-family: Prompt, sans-serif;
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }
}
.it-image__cropper-wrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: -1;
  background-color: rgba(#000,0.6);
  &.show {
    opacity: 1;
    padding-top: 100px;
    z-index: 1060;
  }
  .it-image__cropper {
    position: absolute;
    border-radius: $borderRadius;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    width: 100%;
    max-width: 500px;
    overflow: hidden;
    padding: 12px 20px;
    image-cropper {
      display: flex;
      align-items: center;
      padding: 24px;
      margin-bottom: 40px;
    }
    .it-image__cropper__close {
      background-color: #fff;
      border: 1px solid #fff;
      font-size: 24px;
      @extend .it-center-flex;
      border-radius: 50%;
      cursor: pointer;
      transition: $transition;
      &:before {
        display: block;
        position: absolute;
        top: 8px;
        right: 8px;
        content: '\e96a';
        font-family: it-icons, sans-serif;
      }
    }
  }
  .it-image__cropper-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    padding: 12px 20px;
    width: 100%;
    &:hover {
      .it-button {
        border: none;
        background-color: transparent;
      }
    }
    button {
      background: transparent;
      border: none;
      outline: none;
      font-size: 15px;
      font-family: 'Prompt', sans-serif;
      color: $primary;
      cursor: pointer;
      &.outline {
        color: $gray60;
      }
      &:hover {
        &.primary {
          color: $primaryDark;
        }
      }
    }
  }
}
