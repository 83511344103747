@import "variables";
it-input {
  position: relative;
  width: 100%;
}
.it-input__wrapper {
  .search-reset {
    display: none;
  }
  .it-input {
    display: flex;
    flex-direction: column;
    position: relative;
    input {
      height: 36px;
      border-radius: 8px;
      border: solid 1px $gray22;
      background-color: #fff;
      padding:0 12px;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: $gray80;
      transition: border 300ms ease-out, background-color 300ms ease-out;
      &:focus {
        border: solid 1px $primary;
        box-shadow: 0px 0px 0px 1px $primary;
        border-radius: 8px;
        outline: none;
      }
      &.required {
        border: solid 1px $primary;
        background-color: $primary20;
      }
      &.error {
        border: solid 1px $secondary;
        box-shadow: 0 0 0 1px $secondary;
        background-color: $secondary20;
      }
      &[disabled] {
        border: solid 1px $gray10;
        box-shadow: 0 0 0 1px $gray10;
        background-color: $gray10;
        color: $gray60;
      }
      &[readOnly] {
        border: none;
        background-color: transparent;
        &:focus {
          border: none;
          box-shadow: none;
        }
      }
      &::placeholder {
        color: $gray60;
      }
    }
    textarea {
      border-radius: 8px;
      border: solid 1px $gray22;
      background-color: #fff;
      padding:8px 12px;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      line-height: 24px;
      color: $gray80;
      transition: border 300ms ease-out, background-color 300ms ease-out;
      &:focus {
        border: solid 1px $primary;
        box-shadow: 0px 0px 0px 1px $primary;
        border-radius: 8px;
        outline: none;
      }
      &.required {
        border: solid 1px $primary;
        background-color: $primary20;
      }
      &.error {
        border: solid 1px $secondary;
        box-shadow: 0px 0px 0px 1px $secondary;
        background-color: $secondary20;
      }
      &[disabled] {
        border: solid 1px $gray10;
        background-color: $gray10;
        color: $gray60;
      }
      &[readOnly] {
        border: none;
        background-color: transparent;
      }
      &::placeholder {
        color: $gray60;
      }
    }
    .it-input__label {
      span {
        display: block;
        font-size: 13px;
        color: $gray60;
        font-weight: 500;
        font-family: Roboto, sans-serif;
        margin-bottom: 8px;
        .required {
          color: $secondary;
          margin-left: 4px;
          position: absolute;
          top: -4px;
        }
      }
    }
    .input-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;
      background-color: #000;
      padding: 8px 16px;
      border-radius: 0 8px 8px 0;
      color: #fff;
      cursor: pointer;
      &:before {
        font-size: 32px;
      }
      &.disabled {
        background-color: $gray22;
      }
    }
    &.search {
      justify-content: center;
      input {
        &:focus {
          border: solid 1px $primary60;
        }
        &.filled {
          &:focus {
            border: solid 1px $primary;
          }
        }
        &[disabled] {
          background: $gray10;
        }
      }
      .search-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        height: 40px;
        width: 40px;
        border-radius: 0 8px 8px 0;
        color: $gray60;
        cursor: pointer;
        &:before {
          content: '\e950';
          font-family: $icons;
          font-size: 25px;
        }
        &.filled {
          color: $primary;
        }
        &.disabled {
          color: $gray60;
        }
      }
      .clearSearchTerm {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 54px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $gray22;
        cursor: pointer;
        &:before {
          content: "\e900";
          font-family: $icons;
          color: #fff;
          font-size: 12px;
        }
      }
    }
  }
  .it-input__error-box {
    margin-top: 4px;
    .it-input__error {
      &.single {
        span {
          color: $secondary;
          font-size: 13px;
          line-height: 16px;
        }
      }
      &.multi {
        span {
          cursor: pointer;
          line-height: 16px;
          color: $secondary;
          font-size: 13px;
        }
        ul {
          li {
            color: $secondary;
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }
  }
  .it-input__password-toggle {
    position: absolute;
    right: 0;
    top: 31px;
    width: 35px;
    height: 20px;
    padding-right: 8px;
    margin-top: 10px;
    text-align: center;
    z-index: 999999;
    cursor: pointer;
    &.hide {
      &:before {
        content: '\e93e';
        font-family: it-icons, sans-serif;
        font-size: 20px;
      }
    }
    &.show {
      &:before {
        content: '\e93f';
        font-family: it-icons, sans-serif;
        font-size: 20px;
      }
    }
  }
  .it-input__info-text {
    padding: 4px;
    span {
      font-family: Roboto, sans-serif;
      font-size: 13px;
      line-height: 18px;
      color: $gray60;
    }
  }
}
