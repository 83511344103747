@import "variables";

.it-thorough-pagination {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
  font-size: 14px;
  font-weight: 600;
  .tp-go-to-page {
    flex: initial;
    margin: 0 8px;
    input[type="text"] {
      border: none;
      border-bottom: 1px solid #333;
      width: 16px;
      height: 16px;
      color: #333;
      line-height: 1;
      background: transparent;
      margin: 0 4px;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }
  }
  .tp-rows-per-page {
    .it-dropdown-type1 {
      margin: 0 0 0 6px;
    }
  }
  .tp-pages {
    span {
      display: inline-block;
      margin: 0 8px
    }
    a {
      display: inline-block;
      margin: 0 4px;
      color: $primary;
      text-decoration: underline;
      &.first {
        &:before {
          content: '\e959\e959';
          display: inline-block;
          margin: 0 3px 0 0;
          font: normal 0.75em/1 $icons;
          letter-spacing: -3px;
        }
      }
      &.last {
        &:after {
          content: '\e936\e936';
          display: inline-block;
          margin: 0 0 0 2px;
          font: normal 0.75em/1 $icons;
          letter-spacing: -3px;
        }
      }
      &.prev {
        &:before {
          content: '\e933';
          display: inline-block;
          margin: 0 1px 0 0;
          font: normal 0.75em/1 $icons;
          letter-spacing: 0;
        }
      }
      &.next {
        &:after {
          content: '\e936';
          display: inline-block;
          margin: 0 0 0 1px;
          font: normal 0.75em/1 $icons;
          letter-spacing: 0;
        }
      }
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      &:hover {
        text-decoration: none;
        opacity: 0.5;
      }
    }
  }
}

.it-pagination__wrapper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.it-pagination {
  display: block;
  width: auto;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    li {
      display: block;
      float: left;
      margin: 0;
      padding: 0;
      + li {
        margin: 0 0 0 6px;
      }
      a {
        cursor: pointer;
        @extend .center-flex !optional;
        color: #1073E5;
        border-radius: 22px;
        border: 1px solid #1073E5;
        font-size: 16px;
        line-height: 16px;
        padding: 2px 16px;
        transition: $transition;
        &:before {
          display: block;
          font: 400 1em/1 navus-platform;
        }
        &.active, &.active:hover {
          cursor: default;
          color: #fff;
        }
      }
      &.first {
        a {
          padding: 6px;
          border: none;
          &:before {
            content: '\e959\e959';
            font-family: 'it-icons', sans-serif;
            font-size: 24px;
            display: inline-block;
            margin: 2px 10px 0 0;
            letter-spacing: -30px;
          }
        }
      }
      &.previous {
        a {
          padding: 6px;
          border: none;
          &:before {
            content: '\e959';
            font-family: 'it-icons', sans-serif;
            font-size: 24px;
            margin: 0 3px 0 0;
          }
        }
      }
      &.next {
        a {
          padding: 6px;
          border: none;
          &:before {
            content: '\e95a';
            font-family: 'it-icons', sans-serif;
            font-size: 24px;
          }
        }
      }
      &.last {
        a {
          padding: 6px;
          border: none;
          &:before {
            content: '\e95a\e95a';
            font-family: 'it-icons', sans-serif;
            font-size: 24px;
            display: inline-block;
            margin: 3px 0 0 -8px;
            letter-spacing: -30px;
          }
        }
      }
      &.disabled {
        a {
          color: #313131 !important;
          opacity: 0.6;
          pointer-events: none;
        }
      }
      &.active {
        a, a:hover {
          cursor: default;
          color: #fff;
          background-color: $primary;
        }
      }
    }
  }
}

.it-per-page {
  min-width: 100px;
  width: 33%;
  it-select {
    float: right;
    .it-select {
      width: 100px;
    }
  }
}

.it-total-results {
  span {
    color: $gray60;
    font-size: 13px;
  }
}

@media screen and (max-width: 760px){
  .it-pagination__wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .it-per-page, .it-pagination, .it-total-results {
    display: flex;
    justify-content: flex-start;
  }
}
