@import "../sass/variables";

body {
  &.mobile {
    .it-radio {
      input {
        height: 32px;
        margin-right: 28px;
        &::before {
          font-size: 32px;
          height: 32px;
        }
        &:checked {
          &::after {
            font-size: 32px;
            height: 32px;
          }
        }
      }
    }
  }
}

.it-radio {
  label {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    cursor: pointer;
    &.disabled {
      opacity: 0.6;
    }
    .it-radio__label {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      color: #000;
    }
  }
  input {
    position: relative;
    height: 20px;
    width: 20px;
    margin: 0 8px 0 0;
    font-family: it-icons, sans-serif;
    cursor: pointer;
    &::before {
      content: '';
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border: 1px solid $gray22;
      padding: 2px;
      position: absolute;
      background: #fff;
      color: $primary;
    }
    &:checked {
      &::after {
        content: '';
        height: 14px;
        width: 14px;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 3px;
        background-color: $primary;
      }
    }
    &.mobile {
      height: 32px;
      margin-right: 28px;
      &::before {
        font-size: 32px;
        height: 32px;
      }
      &:checked {
        &::after {
          font-size: 32px;
          height: 32px;
        }
      }
    }
  }
}
