@import "variables";

.it-dropdown {
  position: relative;
  cursor: pointer;
  .it-dropdown__trigger {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    &.disabled {
      pointer-events: none;
      display: none;
    }
  }
  .it-dropdown__list {
    list-style: none;
    padding: 0;
    margin: 0;
    .it-dropdown__item {
      display: flex;
      align-items: center;
      padding: 6px 16px;
      font-family: Hind, sans-serif;
      font-size: 13px;
      cursor: pointer;
      &.primary {
        color: $primary;
        &:hover {
          background: $primary;
          color: #fff;
        }
      }
      &.secondary {
        color: $secondary;
        &:hover {
          background: $secondary;
          color: #fff;
        }
      }
      &.warning {
        color: $warning;
        &:hover {
          background: $warning;
          color: #fff;
        }
      }
      .it-icon {
        font-size: 20px;
        margin-right: 8px;
      }
    }
  }
  .it-dropdown__menu {
    position: absolute;
    padding: 8px 0;
    background: #fff;
    border-radius: $borderRadius;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.20);
    z-index: 2;
    display: none;
    min-width: 120px;
    width: max-content;
    width: -moz-max-content;
    font-family: Prompt, sans-serif;
    &.show {
      display: block;
      animation: showDrop 0.2s linear;
    }
    &.bottom {
      top: calc(100% + 8px);
    }
    &.top {
      bottom: calc(100% + 8px);
    }
    a {
      cursor: pointer;
      color: $gray60;
      display: flex;
      align-items: center;
      .it-icon {
        margin-right: 12px;
        font-size: 16px;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        padding: 0 14px;
        height: 36px;
        display: flex;
        align-items: center;
        &.danger {
          &:hover {
            background-color: $secondary;
            a {
              color: #fff;
            }
          }
        }
        &.action {
          &:hover {
            background-color: $primary;
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }

  @keyframes showDrop {
    0% {
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }
}
