
@import 'libs/infoteam-ui/src/sass/variables';
@import 'libs/infoteam-ui/src/sass/global';
@import 'libs/infoteam-ui/src/sass/typography';
@import 'libs/infoteam-ui/src/sass/grid';
@import 'libs/infoteam-ui/src/sass/fonts';
@import 'libs/infoteam-ui/src/sass/icons';
@import 'libs/infoteam-ui/src/sass/dropdown';
@import 'libs/infoteam-ui/src/sass/carousel';
@import 'libs/infoteam-ui/src/sass/buttons';
@import 'libs/infoteam-ui/src/sass/inputs';
@import 'libs/infoteam-ui/src/sass/checkbox';
@import 'libs/infoteam-ui/src/sass/select';
@import 'libs/infoteam-ui/src/sass/loading';
@import 'libs/infoteam-ui/src/sass/modal';
@import 'libs/infoteam-ui/src/sass/dropdown';
@import 'libs/infoteam-ui/src/sass/radio';
@import 'libs/infoteam-ui/src/sass/tooltip';
@import 'libs/infoteam-ui/src/sass/dropdown';
@import 'libs/infoteam-ui/src/sass/tabs';
@import 'libs/infoteam-ui/src/sass/accordion';
@import 'libs/infoteam-ui/src/sass/toast';
@import 'libs/infoteam-ui/src/sass/image';
@import 'libs/infoteam-ui/src/sass/pagination';
@import 'libs/infoteam-ui/src/sass/sections';
@import "sass/icons";
@import "styles2.scss"; 

h1, h2, h3, h4 {
  font-family: Oswald, sans-serif;
}

h1 {
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 2px;
  &.medium {
    font-size: 34px;
    font-weight: 400;
  }
}

h2 {
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 1px;
}

h3 {
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 34px;
  &.small {
    font-size: 22px;
    letter-spacing: 0;
    line-height: 32px;
    color: #000;
  }
}

h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 3px;
}

p {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  &.small {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 18px;
  }
  &.large {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }
}

a {
  text-decoration: none;
  color:#007CC2;
}

it-button {
  width: 100%;
  max-width: 180px;
  .it-button {
    height: 48px;
    width: 100%;
    padding: 15px;
    border-radius: 0;
    text-align: center;
    &.primary {
      border: none;
      background-color: #000;
      color: #fff;
      &:disabled {
        opacity: 0.6;
        background-color: #000;
      }
      &:hover {
        border: none;
        background-color: #000;
      }
    }
    &.outline {
      border: 1px solid #313131;
      span {
        font-weight: 400;
        color: #313131;
      }
    }
    span {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .transparent {
    border-radius: 0;
    padding: 14px 20px;
    span {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 18px;
      text-transform: uppercase;
    }
  }
}

it-input {
  .it-input__wrapper {
    .it-input {
      input {
        height: 46px;
        border-radius: 0;
        border: 1px solid #C6C6C6;
        padding: 14px 16px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        &:focus {
          border: 1px solid #C6C6C6;
          box-shadow: none;
          outline: none;
        }
        &::placeholder {
          color: #797979;
        }
      }
      .it-input__label {
        span {
          font-family: Roboto, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
          margin-bottom: 8px;
          color: #000;
        }
      }
    }
    .it-input__error-box {
      .it-input__error {
        &.single {
          span {
            font-family: Roboto, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            color: #E72717;
          }
        }
      }
    }
  }
}

.website-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .ns-container {
    width: 100%;
    max-width: 1680px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 20px;
  }
  > header {
    z-index: 2;
  }
  main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    .it-footer {
      display: flex;
      flex-direction: column;
      padding: 40px 32px 0;
      color: #fff;
      background-color: #000;
      .footer-navigation {
        display: flex;
        justify-content: center;
        ul {
          li {
            margin-bottom: 8px;
            font-family: Roboto, sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            &.title {
              margin-bottom: 12px;
              font-family: Oswald, sans-serif;
              font-size: 18px;
              line-height: 26px;
              letter-spacing: 1px;
              text-transform: uppercase;
            }
          }
        }
        a {
          color: #FFF;
        }
      }
      .copyright {
        width: 100%;
        padding: 16px;
        border-top: 2px solid #fff;
        color: #fff;
        text-align: center;
        span {
          font-family: Roboto, sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
      .social-media {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 25px;

        a {
          color: #FFF;
        }

        .it-icon {
          font-size: 28px;
          cursor: pointer;
          &.facebook {
            margin-right: 15px;
          }
        }
      }
    }
  }
}

ul {
  list-style: none;
  margin-block-start: 0;
  padding-inline-start: 0;
}

.search-icon {
  padding-top: 4px;
  &:before {
    font-family: $icons;
    content: '\e950';
  }
}

.it-navigation {
  display: flex;
  height: 60px;
  border-bottom: 1px solid $gray22;
  background-color: #fff;
  .it-logo {
    width: 140px;
    margin: 5px 0 0 15px;
    background: url("assets/images/Garmin-Authorised-Distributor.svg") no-repeat center;
    background-size: contain;
    cursor: pointer;
  }
  .it-navigation__items {
    display: flex;
    margin: 18px auto 0;
    .main-navigation-item {
      margin-right: 30px;
      font-family: Oswald, sans-serif;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      text-transform: uppercase;
      cursor: pointer;
      &.active {
        border-bottom: 4px solid #000;
      }
      .navigation-dropdown {
        position: fixed;
        top: 60px;
        left: 0;
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        height: 0;
        width: 100%;
        overflow: hidden;
        background-color: #fff;
        transition: height .3s ease-in-out;
        z-index: 999;
        &.show {
          height: 480px;
        }
        .navigation-dropdown__list {
          width: 280px;
          padding: 20px 50px 0 20px;
          .list-item__title {
            padding-bottom: 10px;
            border-bottom: 1px solid $gray22;
            font-family: Oswald, sans-serif;
            font-size: 17px;
            font-weight: 400;
            line-height: 40px;
            letter-spacing: 1px;
            text-transform: uppercase;
            cursor: auto;
          }
          .list-items {
            padding-inline-start: 0;
            li {
              font-family: Roboto, sans-serif;
              font-size: 15px;
              font-weight: 400;
              line-height: 36px;
              letter-spacing: 0;
              text-transform: none;
              cursor: pointer;
            }
          }
        }
        .promo-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 385px;
          margin: 24px 32px;
          // padding: 20px 20px 40px;
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
          color: #313131;
          cursor: pointer;
          padding: 0 20px;
          img {
            width: 250px;
          }
          p {
            padding: 10px 0;
          }
        }
      }
    }
  }
  .navigation-actions {
    position: absolute;
    right: 0;
    margin-top: 14px;
    .users {
      font-size: 20px;
      margin-right: 14px;
      cursor: pointer;
    }
    .search {
      font-size: 26px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  it-input {
    position: absolute;
    right: 0;
    width: auto;
    .it-input__wrapper {
      .it-input {
        &.search {
          margin-right: 20px;
          input {
            height: 46px;
            margin-top: 7px;
            padding: 14px 14px 14px 40px;
            border: 1px solid #C6C6C6;
            border-radius: 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            color:#000;
            &:focus {
              border: 1px solid #C6C6C6;
              box-shadow: none;
              outline: none;
            }
            &::placeholder {
              color: #616161;
            }
          }
          .search-icon {
            position: absolute;
            top: 7px;
            bottom: 7px;
            left: 0;
            right: initial;
            color: #000;
          }
          .clearSearchTerm {
            top: 22px;
            right: 16px;
            &::before {
              content: '\e900';
            }
          }
        }

      }
    }
  }
}

.it-navigation__mobile {
  display: none;
  justify-content: space-between;
  height: 56px;
  .menu, .menu-close {
    margin: 4px 16px 0;
    font-size: 38px;
    cursor: pointer;
  }
  .it-logo {
    width: 112px;
    margin-bottom: 12px;
    background: url("assets/images/Garmin-Authorised-Distributor.svg") no-repeat center;
    background-size: contain;
  }
  .it-icon.search {
    margin: 6px 16px 0;
    &:before {
      font-size: 38px;
    }
  }
  .navigation-wrapper {
    position: fixed;
    top: 56px;
    display: flex;
    flex: 1 1 auto;
    height: 0;
    width: 100%;
    overflow: auto;
    background-color: #fff;
    transition: height .3s ease-in-out;
    z-index: 2;
    &.top {
      top: 141px;
    }
    &.show {
      height: 100%;
      padding-bottom: 56px;
    }
    ul {
      display: flex;
      flex-direction: column;
      height: fit-content;
      width: 100%;
      .add {
        font-size: 18px;
      }
      .nav-item {
        display: flex;
        flex-direction: column;
        margin: 0 16px;
        border-bottom: 1px solid $gray22;
        font-family: Oswald, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 1px;
        text-transform: uppercase;
        cursor: pointer;
        &.sub {
          margin: 0;
          border-bottom: 1px solid $gray22;
          &:last-of-type {
            border-bottom: 1px solid #000;
          }
          .title {
            margin-left: 16px;
            padding: 12px 0;
          }
        }
        &.login {
          margin: 0;
          border-top: 1px solid $gray22;
          text-transform: none;
          .title {
            justify-content: initial;
            align-items: center;
            padding: 16px;
            .it-icon {
              margin-right: 12px;
              font-size: 22px;
            }
          }
        }
        .title {
          display: flex;
          justify-content: space-between;
          padding: 12px 0;
          .minus {
            font-size: 24px;
            transform: rotate(90deg);
          }
        }
      .nav-sub__items {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        height: 0px;
        width: 100%;
        padding: 0;
        overflow: hidden;
        background-color: #fff;
        transition: height .3s ease-in-out;
        cursor: pointer;
        &.show {
          height: auto;
          border-top: 1px solid #000;
        }
        .nav-sub-sub__items {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          height: 0;
          width: 100%;
          overflow: hidden;
          background-color: #fff;
          transition: height .3s ease-in-out;
          &.show {
            height: auto;
            padding: 12px 16px;
            border-top: 1px solid #000;
          }
          li {
            font-family: Roboto, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 36px;
            letter-spacing: 0;
            text-transform: none;
          }
        }
      }
      }
    }
  }
  it-input {
    position: fixed;
    top: 56px;
    left: 0;
    padding: 16px;
    border-bottom: 1px solid #C6C6C6;
    background-color: #fff;
    z-index: 999;
    .it-input__wrapper {
      .it-input {
        &.search {
          margin-right: 0;
          input {
            height: 46px;
            margin-top: 7px;
            padding: 14px 14px 14px 40px;
            border: 1px solid #C6C6C6;
            border-radius: 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            color:#000;
            &:focus {
              border: 1px solid #C6C6C6;
              box-shadow: none;
              outline: none;
            }
            &::placeholder {
              color: #616161;
            }
          }
          .search-icon {
            position: absolute;
            top: 7px;
            bottom: 7px;
            left: 0;
            right: initial;
            color: #000;
          }
          .clearSearchTerm {
            top: 22px;
            right: 16px;
            &::before {
              content: '\e900';
            }
          }
        }

      }
    }
  }
}

.main-section {
  position: relative;
  padding: 5px 0;
  background-color: #000;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    border-top: 20px solid #000;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
  }
  .slide-image {
    height: 600px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  it-slide {
    img {
      width: 100%;
      height: 600px;
      object-fit: cover;
    }
  }
  .slide-description {
    position: absolute;
    top: 200px;
    left: 120px;
    color: #fff;
    z-index: 2;
    p {
      margin-bottom: 30px;
      text-transform: none;
    }
  }
  p {
    margin-block-start: 0;
    margin-block-end: 5px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
  }
  h1 {
    color: #fff;
  }
  h4 {
    margin: 10px 0;
    text-align: center;
    color: #fff;
  }
  .swiper-button-prev {
    top: 48%;
    left: 20px;
    margin-top: 0;
    color: #fff !important;
    &::after {
      content: '\e90b' !important;
      font-size: 24px;
      color: #fff !important;
    }
  }
  .swiper-button-next {
    top: 48%;
    right: 20px;
    margin-top: 0;
    color: #fff !important;
    &::after {
      content: '\e90c' !important;
      font-size: 24px;
      color: #fff;
    }
  }
}

.slider-section {
  .swiper-container {
    padding: 0 60px;
  }
  it-slide  {
    .slide-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 430px;
      max-height: 430px;
      margin: 50px 0px;
      padding: 30px 40px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
      text-align: center;
      overflow: hidden;
      img {
        width: 230px;
      }
      h3 {
        margin: 0 0 12px 0;
      }
      p {
        margin: 0;
      }
    }
  }
  .swiper-button-prev, .swiper-button-next {
    display: none;
    top: 48%;
    margin-top: 0;
    &::after {
      font-size: 27px;
      color: #282828 !important;
      background-color: #EEEEEE;
      padding: 14px 12px;
    }
  }
  .swiper-button-prev {
    left: 20px;
    &::after {
      content: '\e90b' !important;
    }
  }
  .swiper-button-next {
    right: 20px;
    &::after {
      content: '\e90c' !important;
    }
  }
}

.wearable-technology__section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  h2 {
    margin-top: 0;
    margin-bottom: 4px;
  }
  p {
    margin-bottom: 30px;
  }
  it-button {
    margin-bottom: 42px;
    .transparent {
      border: 1px solid #000;
      border-radius: 0;
      padding: 14px 20px;
      color: #000;
      span {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 18px;
        text-transform: uppercase;
      }
    }
  }
}

.image-section {
  display: flex;
  padding: 0 60px;
  margin-bottom: 30px;
  .content-container {
    max-height: 617px;
    width: 100%;
    .it-col {
      margin-top: 0;
    }
    .black-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 110px;
      color:#fff;
      background-color: #000;
      h2 {
        margin-bottom: 20px;
      }
      p {
        margin: 0 0 25px 0;
      }
    }
    .image {
      height: 617px;
      width: 100%;
      transition: height .3s ease;
      &.navigation {
        background: url("assets/images/category-photo-navigacije.jpg") no-repeat center;
        background-size: cover;
      }
      &.sport {
        background: url("assets/images/category-photo-sport-i-fitness.jpg") no-repeat center;
        background-size: cover;
      }
      &.activity {
        background: url("assets/images/category-photo-aktivnosti-priroda.jpg") no-repeat center;
        background-size: cover;
      }
      &.nautical {
        background: url("assets/images/category-photo-nautika.jpg") no-repeat center;
        background-size: cover;
      }
    }
  }
}

.newsletter-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  .newsletter-sign-in {
    display: flex;
    width: 100%;
    max-width: 440px;
    padding: 0 16px;
    .arrow-right {
      font-size: 46px;
      color: #fff;
      background-color: #000;
    }
  }

}

.login, .registration, .password-change, .password-change-request {
  h1 {
    margin: 30px 0;
  }
  .form-data {
    max-width: 440px;
  }
}

.login {

}

@media (max-width: 1450px) {
  .slider-section {
    .swiper-button-prev, .swiper-button-next {
      display: flex;
    }
  }
}

@media (max-width: 1330px) {
  .it-navigation {
    display: none;
  }
  .it-navigation__mobile {
    display: flex;
  }
}

@media (max-width: 992px) {
  .website-root {
      main {
      .it-footer {
        padding-top: 20px;
        .footer-navigation {
          padding-left: 16px;
          .social-media {
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0 0 25px 0;
          }
        }
        .copyright {
          text-align: start;
        }
      }
    }
  }
  .image-section {
    display: block;
    padding: 0 16px;
    .content-container {
      max-height: initial;
      &.reverse {
        flex-direction: column-reverse;
      }
      .black-container {
        padding: 0 20px 20px;
      }
      .image {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background:linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
        }
      }
    }
  }
}

@media (max-width: 692px) {
  .wearable-technology__section {
    display: block;
    padding: 0 16px;
    p {
      margin-bottom: 25px;
    }
  }
  .main-section {
    it-slide {
      img {
        height: auto;
      }
    }
  }
}

@media (max-width: 500px) {
  .main-section {
    .slide-description {
      position: initial;
      text-align: center;
      h1 {
        margin: 32px 0 20px;
        font-size: 28px;
        letter-spacing: 1px;
      }
      p {
        font-size: 18px;
        letter-spacing: 0.5px;
        line-height: 20px;
        margin-bottom: 25px;
      }
      it-button {
        .transparent {
          margin-bottom: 60px;
        }
      }
    }
  }
  .image-section {
    .content-container {
      .image {
        height: 235px;
      }
    }
  }
  .newsletter-section {
    margin-bottom: 50px;
  }
}

.nav-bottom {
  display: none;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  line-height: 20px;
  margin-top: 5px;
  color: #797979;
  span {
    margin: 0 8px;
  }
}


.info-text {
  color: #797979;
}

//Product
.ns-container.product-category {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  overflow: hidden;

  .product-category__header {
    flex: 0 0 80px;
    padding-top: 10px;
  }

  .product-category__content {
    flex: 1 1 100%;
    overflow: hidden;

    > .it-col {
      height: 100%;
      overflow: auto;
    }
  }

  .product-category__info {
    h2, p {
      margin: 0;
    }
  }
  .sort {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    p {
      margin: 0 10px 0 0;
      line-height: 20px;
      color: #797979;
    }
    .it-select {
      width: 200px;
      .it-select__control-display {
        height: 40px;
        padding: 8px 12px;
        border: 1px solid #dadada;
        color: #313131;
        .it-select__value {
          font-family: Rubik, sans-serif;
          font-size: 15px;
          line-height: 24px;
        }
        .it-select__deselectAll {
          right: 42px;
        }
      }
    }
    // .it-select-overlay-container {
    //   .it-select__list-container {
    //     .it-select__options-container {
    //       li {
    //         .option-text {
    //           font-family: Rubik, sans-serif;
    //           font-size: 15px !important;
    //           line-height: 24px;
    //         }
    //       }
    //     }
    //   }
    // }
  }
  .sort-container {
    height: 50px;
    display: none;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    span {
      font-family: Rubik, sans-serif;
      font-size: 13px;
      font-weight: 500;
      color: #313131;
      cursor: pointer;
      &:first-of-type {
        width: 200px;
      }
    }
    .separator {
      height: 30px;
      border: 1px solid #C6C6C6;
      margin-right: 12px;
    }
    .sort-option {
      display: flex;
      flex-direction: column;
      .selected-sort__option {
        font-weight: 400;
      }
    }
  }
  .product-card {
    position: relative;
    // height: 385px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
    padding: 16px 15px;
    .product-image {
      position: relative;
      display: flex;
      justify-content: center;
      min-height: 220px;
      img {
        position: relative;
        width: 200px;
        margin: 0 auto 24px;
        z-index: -1;
      }
      .product {
         position: absolute;
         left: -15px;
         width: auto;
         padding: 5px 5px  5px 10px;
         font-family: Oswald, sans-serif;
         font-size: 16px;
         font-weight: 500;
         letter-spacing: 1px;
         text-transform: uppercase;
         color: #000;
         &::after {
          content: '';
          position: absolute;
          top: 0;
          right: -15px;
          width: 0;
          height: 0;
          border-width: 34px 15px 0 0;
          border-color: #6DCFF6 transparent transparent transparent;
          border-style: solid;
         }
         &.top {
           top: 40px;
         }
         &.discount {
          background-color: #6DCFF6;
         }
        &.new {
          background-color: #6DCFF6;
        }
      }
    }
    .product-title {
      font-family: Oswald, sans-serif;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 4px;
      color: #313131;
    }
    .price {
      font-family: Roboto, sans-serif;
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 4px;
      color: #000;
    }
    .old-price {
      font-family: Roboto, sans-serif;
      font-size: 19px;
      font-weight: 400;
      text-decoration: line-through;  
      color: #000;
    }
    .stock_status {
      display: none;
    }
    .product-actions {
      position: absolute;
      bottom: 10px;
      right: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: auto;
      it-button {
        display: none;
      }
      .compare {
        display: flex;
        align-items: center;
        margin-right: 8px;
        font-family: Rubik, sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: #313131;
        cursor: pointer;
        &:hover {
          color: #0041FF;
          .compare-square {
            color: #0041FF;
          }
        }
        .compare-square {
          font-size: 30px;
          margin-right: 10px;
          color: #313131;
        }
      }
    }
  }
}

.product-details {
  .product-details__container {
    max-width: 1400px;
    width: 100%;
    margin: 32px auto 0;

    .it-section {
      margin: 25px 0;
    }
  }
  .image-wrapper {
    width: 100%;
    height: 100%;
    .main-image {
      text-align: center;
      img {
        max-height: 292px;
        max-width: 292px;
      }
    }
    .image-list {
      width: 100%;
      display: flex;
      overflow: auto;
      img {
        max-height: 65px;
        max-width: 65px;
        cursor: pointer;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .price {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 4px;
  }
  .old-price {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-decoration: line-through;
    color: #7e7e7e;
  }
  .small-text {
    font-family: Rubik, sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #7e7e7e;
    display: flex;
    align-items: center;
  }
  it-slides {
    display: none;
  }
}

.product-filters {
  margin-bottom: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #C6C6C6;
  .filter-item {
    margin-bottom: 22px;
    .filter-title {
      font-size: 15px;
      font-weight: 500;
      color: #313131;
    }
  }
  .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &.price {
      it-input {
        input {
          width: 100%;
        }
        &:first-of-type {
          margin-right: 10px;
        }
      }
    }
    .it-checkbox {
      height: auto !important;
      input {
        height: 22px;
        &::before {
          height: 20px;
          width: 20px;
          border-radius: 4px;
          border: 1px solid #C6C6C6;
          font-size: 20px;
        }
        &::after {
          border-radius: 4px;
          border: none;
          color: #1073E5;
        }
      }

      .it-checkbox__label {
        font-family: Rubik, sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        padding-top: 0;
        color: #313131;
      }
    }
    .product-number {
      font-family: Rubik, sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      color: #797979;
    }
  }
}

.product-info {
  width: max-content;
  width: -moz-max-content;
  padding: 5px 10px;
  border-radius: 12px;
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 8px;
  &.discount {
   background-color: #E21800;
  }
  &.new {
    background-color: #1073E5;
  }
}

.stock-status {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #42D915;
  margin-right: 10px;
  &.red {
    background-color: $secondary;
  }
  &.yellow {
    background-color: $warning;
  }
}

it-button {
  &.compare {
    position: fixed;
    right: 15px;
    bottom: 15px;
    max-width: 230px;
    z-index: 1002;
    .it-button {
      height: 48px;
      padding: 10px 20px;
      &:hover {
        border: none;
      }
      &.primary {
        background-color: #313131;
        color: #fff
      }
    }
  }
}
.compare {
  display: flex;
  align-items: center;
  margin-right: 8px;
  font-family: Rubik, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #313131;
  cursor: pointer;
  &:hover {
    color: #0041FF;
    .compare-square {
      color: #0041FF;
    }
  }
  .compare-square {
    font-size: 30px;
    margin-right: 10px;
    color: #313131;
  }
}

.product-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .product-card {
    height: 338px;
    width: 286px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
    padding: 16px 15px;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
    .product-image {
      position: relative;
      display: flex;
      justify-content: center;
      min-height: 220px;
      img {
        position: relative;
        width: 200px;
        margin: 0 auto 24px;
        z-index: -1;
      }
      .product {
         position: absolute;
         left: 0;
         width: auto;
         padding: 5px 10px;
         border-radius: 12px;
         font-family: Rubik, sans-serif;
         font-size: 12px;
         font-weight: 500;
         letter-spacing: 1px;
         text-transform: uppercase;
         color: #fff;
         &.top {
           top: 30px;
         }
         &.discount {
          background-color: #E21800;
         }
        &.new {
          background-color: #1073E5;
        }
      }
    }
    .product-title {
      font-family: Oswald, sans-serif;
      font-size: 17px;
      font-weight: 400;
      margin-bottom: 4px;
      color: #313131;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .price {
      font-family: Roboto, sans-serif;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 4px;
    }
    .old-price {
      font-family: Oswald, sans-serif;
      font-size: 15px;
      font-weight: 400;
      text-decoration: line-through;
      color: #7e7e7e;
    }
    .product-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: auto;
      .compare {
        display: flex;
        align-items: center;
        margin-right: 8px;
        font-family: Rubik, sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: #313131;
        cursor: pointer;
        &:hover {
          color: #0041FF;
          .compare-square {
            color: #0041FF;
          }
        }
        .compare-square {
          font-size: 30px;
          margin-right: 10px;
          color: #313131;
        }
      }
    }
  }
}

.product-properties {
  border: 1px solid $gray22;
  .product-property {
    border-bottom: 1px solid $gray22;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    span {
      width: 40%;
      word-break: break-word;
      &:last-child {
        padding-left: 5px;
        width: 60%;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

// Auth

.login, .registration, .password-change, .password-change-request {
  h1 {
    margin: 30px 0;
  }
  .form-data {
    max-width: 440px;
  }
}

.password-change {
  p.light {
    color: #797979;
  }
}

.registration {
  width: 100%;
  margin-bottom: 60px !important;
  .done {
    margin-top: 30px;
    text-align: center;
    &::before {
      font-size: 42px;
      color: #27B33D;
    }
  }
  .terms-of-conditions {
    flex-direction: row !important;
    align-items: center;
  }
  .newsletter {
    position: relative;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    margin-top: 30px;
    // background: url("assets/images/newsletter-bg.jpg") no-repeat center;
    background-size: cover;
    .newsletter-info__container {
      position: absolute;
      right: 200px;
      h1 {
        color: #fff;
        margin: 0;
        &:first-of-type {
          font-weight: 200;
        }
      }
      .it-button {
        margin-top: 30px;
        border: 1px solid #fff;
        &.outline {
          background-color: transparent;
          &:hover {
            .it-button__text, span, &:before {
              color: #fff !important;
            }
          }
          span {
            color: #fff;
          }
        }
      }
    }
  }
}

.profile-page {
  .profile-navigation {
    li {
      display: flex;
      align-items: center;
      font-family: Rubik, sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      border-radius: 22px;
      padding: 10px 20px;
      margin-bottom: 10px;
      color: #313131;
      cursor: pointer;
      .ns-icon {
        font-size: 28px;
        margin-right: 10px;
      }
      &.active, &:hover {
        color: #fff;
        background-color: #1073E5;
      }
    }
  }
  .delete-profile {
    padding: 20px 0 0 0;
    border-top: 1px solid #C6C6C6;
    p {
      display: flex;
      align-items: center;
      cursor: pointer;
      .ns-icon {
        font-size: 20px;
        margin-right: 12px;
        color: #313131;
      }
    }
  }
  .order-wrapper {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
    &.background-none {
      padding: 0;
      box-shadow: none;
    }
    &.price-container {
      .amount {
        font-family: Oswald, sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        color: #797979;
      }
      .price {
        font-family: Oswald, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #313131;
      }
    }
    .order-info__wrapper {
      flex-direction: row;
    }
    .order-info {
      padding: 20px 0;
      border-top: 1px solid #C6C6C6;
    }
    .order-status {
      font-family: Rubik, sans-serif;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      padding: 5px 15px;
      border-radius: 15px;
      text-transform: capitalize;
      color: #fff;
      background-color: #70ABEF;
      &.processing {
        background-color: #1073E5;
      }
      &.on-delivery {
        background-color: #FFA931;;
      }
      &.delivered {
        background-color: #27B33D;
      }
      &.canceled {
        background-color: #E72717;
      }
    }
    .product-wrapper {
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding: 10px 12px;
      border-radius: 8px;
      border: 1px solid #C6C6C6;
      &.large {
        height: 150px;
        align-items: flex-start;
        position: relative;
        padding: 15px;
        img {
          height: 120px;
          margin-right: 24px;
        }
        .price {
          position: absolute;
          right: 15px;
          bottom: 15px;
          span {
            font-family: Oswald, sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #313131;
          }
        }
      }
      img {
        height: 60px;
        margin-right: 8px;
      }
      .product-name {
        font-family: Oswald, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        color: #313131;
      }
    }
  }
  .address-container {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
    .header {
      margin-bottom: 20px;
      .title {
        font-family: Rubik, sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        color: #313131;
      }
      .it-icon {
        font-size: 20px;
      }
    }
    .body {
      p {
        margin-bottom: 0;
      }
    }
    .footer {
      flex-direction: row;
      justify-content: end;
      a {
       &:first-of-type {
          margin-right: 15px;
          color: #797979;
        }
      }

    }
  }
  .profile-navigation__responsive {
    display: none;
    .it-dropdown {
      display: flex;
      align-items: center;
      padding: 12px 25px;
      margin-bottom: 4px;
      border-radius: 24px;
      font-family: Rubik, sans-serif;
      font-size: 17px;
      font-weight: 500;
      line-height: 24px;
      color: #fff;
      background: #1073E5;
      .ns-icon {
        font-size: 28px;
        margin-right: 16px;
      }
      .it-icon {
        position: absolute;
        right: 25px;
        font-size: 24px;
      }
      &.show {
        border-radius: 24px 24px 0 0;
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
        color: #313131;
        background-color: #fff;
      }
      .it-dropdown__menu {
        width: 100%;
        top: 48px;
        padding: 0;
        border-radius: 0 0 24px 24px;
        box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.15);
        li {
          height: 46px;
          padding: 12px 25px;
          font-family: Rubik, sans-serif;
          font-size: 17px;
          font-weight: 400;
          line-height: 24px;
          color: #313131;
          &:hover {
            font-weight: 500;
            color: #fff;
            background-color: #1073E5;
            &:last-of-type {
              border-radius: 0 0 24px 24px;
            }
          }
        }
      }
    }
  }
  .order-cancel {
    font-size: 16px;
    color: #797979;
  }
}

.page-card {
  height: 100%;
  padding-bottom: 15px;
  border-radius: 8px;
  box-shadow:  0 2px 9px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  .image-placeholder {
    height: 175px;
    width: 100%;
    border-radius: 8px 8px 0 0;
    margin-bottom: 20px;
    // background: url('assets/images/news-placeholder.svg') no-repeat center;
    background-size: cover;
  }
  img {
    height: 175px;
    width: 100%;
    border-radius: 8px 8px 0 0;
    margin-bottom: 20px;
    object-fit: cover;
    object-position: center;
  }
  .page-info {
    padding: 0 15px;

    h3 {
      letter-spacing: 0;
    }
  }
  .info-text {
    margin-bottom: 20px;
  }
}

ns-filters {
  ul {
    list-style: none;
  }
}

ns-login, ns-registration, ns-password-change, ns-password-change-request, ns-profile, ns-order-list, ns-addresses, ns-order-details {
  display: flex;
  flex: 1 1 auto;
}

@media (max-width: 1580px) {
  .ns-navigation {
    .ns-navigation__wrapper {
      .ns-navigation__container {
        .ns-navigation__items {
          .main-navigation-item {
            .navigation-dropdown__wrapper {
              .navigation-dropdown {
                .navigation-dropdown__list {
                  min-width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1380px) {
  .ns-navigation {
    display: none;
  }
  .ns-navigation__mobile {
    display: flex;
    padding: 16px 20px;
  }
}

@media (max-width: 992px) {
  ns-footer {
    padding: 0 12px;
  }
  .website-root {
    main {
      .ns-footer {
        .footer-navigation {
          ul {
            &.payment {
              text-align: center;
            }
            li {
              font-size: 16px;
              &.title {
                margin-bottom: 18px;
                text-align: initial;
              }
              &.social-media {
                .ns-icon {
                  font-size: 32px;
                }
              }
            }
          }
        }
        .copyright {
          flex-direction: column;
          justify-content: flex-start;
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
  .product-category {
    .product-category-filters__wrapper {
      display: none;
    }
   .sort {
      display: none !important;
    }
    .sort-container {
      display: flex !important;
    }
  }
  .registration {
    .newsletter {
      .newsletter-info__container {
        right: initial;
      }
    }
  }
  .profile-page {
    .profile-navigation {
      display: none;
    }
    .profile-navigation__responsive {
      display: flex;
    }
    .order-wrapper {
      &.price-container {
        padding: 0;
        box-shadow: none;
      }
      .order-info {
        padding: 0;
        &:last-of-type {
          padding-top: 20px;
        }
        .border {
          &.top {
            border-top: 1px solid #C6C6C6;
            padding-top: 20px;
          }
          &.bottom {
            border-bottom: 1px solid #C6C6C6;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}

.product-filters {
  &.in-modal {
    &:last-of-type {
      border-bottom: 1px solid #C6C6C6;
    }
    .filter-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
      cursor: pointer;
      .filter-title {
        font-size: 17px;
      }
      .it-icon {
        display: block;
        font-size: 24px;
      }
    }
    .filter-options {
      display: none;
      margin-top: 18px;
      &.show {
        display: block;
      }
    }

    .filter {
      &.price {
        margin-top: 20px;
      }
      .it-checkbox {
        height: 24px;
        input {
          height: 24px;
          &::before {
            height: 22px;
            width: 22px;
          }
        }
        .it-checkbox__label {
          font-size: 17px;
        }
      }
      .product-number {
        font-size: 17px;
      }
    }
  }
}

@media (max-width: 500px) {
  .website-root {
    main {
      .ns-footer {
        margin-top: 20px;
      }
    }
  }
  .nav-bottom {
    position: absolute;
    bottom: 0;
    height: 0;
    opacity: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 1003;
    transition: height .2s linear, opacity .1s linear;
    &.show {
      opacity: 100%;
      height: 72px;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      .ns-icon {
        font-size: 30px;
        margin-bottom: 4px;
        color: #313131;
      }
      span {
        font-family: Rubik, sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
        color: #313131;
      }
    }
  }
  .registration {
    margin-bottom: 0 !important;
    &.success-message {
      padding: 0 !important;
    }
    .it-button.outline span {
      font-size: 17px;
    }
    .newsletter {
      justify-content: initial;
      align-items: initial;
      // background: url("assets/images/newsletter-bg-mobile.jpg") no-repeat center;
      background-size: cover;
      border-radius: 0;
      .newsletter-info__container {
        top: 20px;
        left: 20px;
        h1 {
         font-size: 20px;
        };
      }
    }
  }
  .password-change {
    h1 {
      font-size: 22px;
      line-height: 33px;
    }
    p.large {
      font-size: 17px;
    }
    it-button {
      max-width: 100%;
      .it-button {
        span {
          font-size: 17px;
        }
      }
    }
  }
  .profile-page {
    .order-wrapper {
      position: relative;
      &.background-none {
        .order-status {
          right: 0;
        }
      }
      .orders-info__wrapper {
        flex-wrap: wrap;
        position: relative;
        div {
          &:first-of-type {
            width: 100%;
          }
        }
      }
      .order-status {
        position: absolute;
        top: 15px;
        right: 15px;
      }
      .product-wrapper {
        margin: 0 0 10px 0;
        .product-name {
          font-size: 14px;
        }
      }
    }
  }
  .page-card {
    .info-text {
      font-size: 16px;
    }
  }
  .product-details {
    it-slides {
      display: block;
      .product-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
        padding: 16px 15px;
        margin: 0px 4px;
        &:last-child {
          margin-right: 0;
        }
        .product-image {
          position: relative;
          display: flex;
          justify-content: center;
          min-height: 220px;
          img {
            position: relative;
            width: 200px;
            margin: 0 auto 24px;
            z-index: -1;
          }
          .product {
             position: absolute;
             left: 0;
             width: auto;
             padding: 5px 10px;
             border-radius: 12px;
             font-family: Rubik, sans-serif;
             font-size: 12px;
             font-weight: 500;
             letter-spacing: 1px;
             text-transform: uppercase;
             color: #fff;
             &.top {
               top: 30px;
             }
             &.discount {
              background-color: #E21800;
             }
            &.new {
              background-color: #1073E5;
            }
          }
        }
        .product-title {
          font-family: Oswald, sans-serif;
          font-size: 17px;
          font-weight: 400;
          margin-bottom: 4px;
          color: #313131;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .price {
          font-family: Roboto, sans-serif;
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 4px;
        }
        .old-price {
          font-family: Oswald, sans-serif;
          font-size: 15px;
          font-weight: 400;
          text-decoration: line-through;
          color: #7e7e7e;
        }
        .product-actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: auto;
          .compare {
            display: flex;
            align-items: center;
            margin-right: 8px;
            font-family: Rubik, sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            color: #313131;
            cursor: pointer;
            &:hover {
              color: #0041FF;
              .compare-square {
                color: #0041FF;
              }
            }
            .compare-square {
              font-size: 30px;
              margin-right: 10px;
              color: #313131;
            }
          }
        }
      }
    }
    it-button {
      max-width: 100%;
    }
    it-tabs {
      .it-tabs {
        .it-tabs__nav {
          ul {
            padding: 0;
            overflow-x: auto;
            overflow-y: hidden;
            li {
              margin: 0;
              min-width: 115px;
            }
          }
        }
      }
    }
    .image-wrapper {
      display: none;
    }
    .compare {
      justify-content: center;
    }
    .product-cards-wrapper {
      display: none;
    }
  }
}

.it-select .it-select__control-display {
  border-radius: 0;
  height: 46px;
}

.it-input__wrapper .it-input input:focus {
  border-radius: 0 !important;
}

.product-details {
  .it-tabs {
    position: relative;
    width: 100vw;
    margin: 0 0 0 -50vw;
    left: 50%;
    height: 80px;
    background-color: #EEEEEE;
    border: none;
    ul {
      justify-content: center;
      li {
        height: 100%;
        margin: 0 15px;
        background-color: transparent !important;
        &.active {
          border-bottom: 5px solid #000;
        }
        a {
          font-family: Roboto, sans-serif;
          font-size: 16px;
          font-weight: 500;
          text-transform: uppercase;
          color: #000 !important;
        }
      }
    }
  }
}

.slider-novo {
  it-slide {
    .slide-wrapper {
      width: auto;
      margin: 0 20px;
      box-shadow: 0 2px 3px 1px #d9d9d9; //0 1px 6px rgb(0 0 0 / 20%);

      img {
        width: 80%;
        height: 200px;
      }

      .text-content {
        background-color: #fff !important;
        color: #313131 !important;
      }
    }
  }
}

.scroll-to-top {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 0;
  width: 0;
  border: #fff 1px solid;
  background-color: #000;
  color: #fff;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  
  transition: opacity .4s linear;

  .it-icon:before {
    font-size: 24px;
  }

  &.show {
    height: 40px;
    width: 40px;
    opacity: 1;
  }
}