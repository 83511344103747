@import "variables";
body {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  color: $primaryText;
}
h1, h2, h3, h4 {
  font-family: Prompt, sans-serif;
  font-weight: 500;
  margin-top: 0;
}
h1 {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 1px;
  color: $primaryText;
  &.breadcrumbs {
    line-height: 28px;
  }
}
h2 {
  font-size: 20px;
  line-height: 32px;
  color: $gray80;
  margin-bottom: 0;
}
h3 {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 1px;
  margin-bottom: 16px;
  color: $gray80;
}
h4 {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: $gray80;
}
p {
  line-height: 20px;
  letter-spacing: 0;
  margin-top: 0;
}
a {
  text-decoration-line: none;
  color: $primary;
}

label {
  font-size: 14px;
  font-weight: 500;
  color: $gray60;
  line-height: 16px;
  letter-spacing: 0;
}

.it-label {
  display: block;
  font-size: 13px;
  color: $gray60;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  margin-bottom: 8px;
}

hr {
  width: 100%;
}

.it-bold {
  font-weight: 500;
}
.it-underline {
  text-decoration: underline;
}
.it-uppercase {
  text-transform: uppercase !important;
}

.it-capitalize {
  text-transform: capitalize;
}

.it-text-info {
  color: $gray60;
  font-size: 13px;
  font-weight: 500;
  font-family: Prompt, sans-serif;
  margin-top: 8px;
}

//text
.it-text-prompt {
  font-family: Prompt, sans-serif;
}
.it-text-roboto {
  font-family: Roboto, sans-serif;
}
.it-text-left {
  text-align: left;
}
.it-text-right {
  text-align: right;
}
.it-text-center {
  text-align: center;
}
.it-text-through {
  text-decoration: line-through;
}
//text-color
.it-text-color-primary {
  color: $primary;
}
.it-text-color-secondary {
  color: $secondary;
}
.it-text-color__gray-80 {
  color: $gray80;
}
.it-text-color__gray-60 {
  color: $gray60;
}

//text-size
.it-text-small {
  font-size: 13px;
}
