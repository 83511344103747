.compare-page {
  position: relative;
  it-accordion {
    .it-accordion {
      margin-bottom: 0;
    }
    .it-accordion__header {
      border: 1px solid $gray22;
      border-radius: 0 !important;
    }
    .it-accordion__body {
      padding: 0;
    }

    .content {
      display: flex;
    }

  }
  .filter-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .filter {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .label {
        margin-right: 8px;
        font-size: 15px;
        font-family: Rubik, sans-serif;
        color: #797979;
      }
      it-select {
        width: 250px;
      }
    }
  }
  .compare-products__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .product {
      width: 100%;
      text-align: center;
      position: relative;
      border-top: 1px solid $gray22;
      border-left: 1px solid $gray22;
      &:last-child {
        border-right: 1px solid $gray22;
      }
      .menu-close {
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 24px;
        color: $gray60;
        cursor: pointer;
      }
      img {
        width: 200px;
      }
      .name {
        width: 200px;
        margin: 0 auto 15px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .compare-product-properties {
    border: 1px solid $gray22;
    border-top: none;
    width: 100%;
    .product-property {
      border-bottom: 1px solid $gray22;
      padding: 12px;
      display: flex;
      flex-direction: column;
      margin-top: 0;
      min-height: 68px;
      min-width: 200px;
      &:nth-child(odd) {
        background-color: #f1f1f1;
      }
      &:last-child {
        border-bottom: none;
      }
      .title {
        color: #797979;
        margin-bottom: 8px;
      }
    }
  }
  .compare-product-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-left: 1px solid $gray22;
      border-bottom: 1px solid $gray22;
      padding: 12px 16px;
      &:last-child {
        border-right: 1px solid $gray22;
      }
      h4 {
        margin-bottom: 8px;
      }
    }
    it-button {
      .it-button {
        max-width: 180px;
        margin-top: 16px;
      }
    }
  }
}
.compare-bubble {
  position: fixed;
  right: -450px;
  bottom: 15px;
  width: 400px;
  background-color: #fff;
  min-height: 250px;
  border-radius: $borderRadius;
  overflow: hidden;
  box-shadow: 0 0 12px 0 rgba($color: #000000, $alpha: .25);
  padding: 20px 20px 15px 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transition: right .3s ease-in-out;
  &.show {
    right: 15px;
  }
  .body {
    flex: 1 auto;
    overflow: auto;
    margin-bottom: 15px;
    h4 {
      font-weight: 600;
      font-family: Rubik, sans-serif;
    }
    .product-category__wrapper {
      border-bottom: 1px solid $gray22;
      padding: 15px 0;
      &:first-child {
        border-top: 1px solid $gray22;
      }
    }
    .product-category {
      margin-bottom: 12px;
      it-button {
        .it-button {
          margin-top: 15px;
          width: 170px;
        }
      }
    }
    .product {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: $borderRadius;
      overflow: hidden;
      border: 1px solid $gray22;
      padding: 10px;
      margin-bottom: 5px;
      .content {
        display: flex;
        align-items: center;
        max-width: 300px;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .ns-icon {
        font-size: 24px;
        color: $gray60;
        cursor: pointer;
      }
    }
  }
  .footer {
    text-align: right;
    p {
      margin-bottom: 0;
      cursor: pointer;
    }
  }
}
.price {
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 4px;
  color: #1073E5;
  &.discount {
    color: #E21800;
  }
}
.old-price {
  font-family: Manrope, sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-decoration: line-through;
  color: #7e7e7e;
}

.main-stretch {
  flex: 1;
}
.cart-page {
  it-tabs {
   .it-tabs {
     border-bottom: none;
     ul {
       margin: 0;
       padding: 0;
       background-color: $gray5;
       border-radius: 20px;
       overflow: hidden;
       li {
         width: 100%;
         margin: 0;
         padding: 8px 20px;
         transition: none;
         &.active {
          border-bottom: none;
          border-radius: 20px;
          overflow: hidden;
          background-color: $primary;
          a {
            color: #fff;
          }
         }
       }
     }
   }
  }
  hr {
    color: $gray22;
  }
  .cart-details__container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto 20px;
  }
  .cart-product__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    box-shadow: 0 2px 9px 0 rgba($color: #000000, $alpha: .15);
    padding: 15px;
    margin-bottom: 15px;
    &:first-child {
      margin-top: 25px;
    }
    .left {
      display: flex;
      align-items: flex-start;
      img {
        max-width: 120px;
        max-height: 120px;
      }
      .name {
        font-size: 17px;
        font-family: Manrope, sans-serif;
        margin-bottom: 15px;
      }
      .content {
        display: flex;
        flex-direction: column;
      }
      .title {
        font-size: 18px;
        margin-bottom: 5px;
        color: $gray80;
      }
      .info {
        font-size: 16px;
        color: $gray80;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: right;
      &.bottom {
        justify-content: flex-end;
      }
      .delete {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        .ns-icon {
          display: inline-block;
          margin-right: 12px;
          font-size: 18px;
        }
        &:hover {
          color: $secondary;
        }
      }
      h3 {
        margin-bottom: 0;
      }
    }
  }
  .cart-additional {
    width: 100%;
    margin-top: 73px;
  }
  .actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    it-button {
      &:first-child {
        margin-right: 15px;
      }
    }
  }
  .terms {
    width: 100%;
    text-align: left;
    margin-bottom: 45px;
  }
  .confirmation-title {
    font-size: 18px;
    font-weight: 600;
    margin-top: 24px;
  }
  .confirmation-info__container {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: $gray80;
    margin-bottom: 20px;
    span {
      margin-bottom: 5px;
    }
  }
  .empty {
    height: 100%;
    width: 100%;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.start {
      align-items: flex-start;
    }
    .ns-icon {
      font-size: 48px;
      margin-bottom: 28px;
    }
    h3 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 30px;
    }
  }
  .user-name {
    font-size: 18px;
    font-family: Rubik, sans-serif;
    font-weight: 500;
  }
  .addresses_wrapper {
    margin-top: 0;
    position: relative;
    cursor: pointer;
    .address {
      padding: 15px;
      box-shadow: 0 2px 9px 0 rgba($color: #000000, $alpha: .15);
      margin-bottom: 15px;
    }
    .address-name {
      font-size: 18px;
      font-family: Rubik, sans-serif;
      font-weight: 500;
    }
    .address-item {
      display: block;
      margin-bottom: 5px;
    }
    .action {
      position: absolute;
      top: 15px;
      right: 5px;
    }
  }
  .tab-title {
    display: none;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
  }
}
.it-quantity {
  position: relative;
  width: 160px;
  .action {
    height: 36px;
    width: 36px;
    overflow: hidden;
    border: 1px solid #DADADA;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    z-index: 3;
    &.plus {
      right: 0;
    }
  }
  it-input {
    .it-input__wrapper {
      display: flex;
      justify-content: center;
      input {
        width: 88px;
        border-left: none;
        border-right: none;
        border-radius: 0;
        text-align: center;
        &:focus {
          border: 1px solid #DADADA;
          border-left: none;
          border-right: none;
          box-shadow: none;
          border-radius: 0;
          outline: none;
        }
        &:read-only {
          border: 1px solid #DADADA;
          border-left: none;
          border-right: none;
          pointer-events: none;
          &:focus {
            border: 1px solid #DADADA;
            border-left: none;
            border-right: none;
            box-shadow: none;
            border-radius: 0;
            outline: none;
          }
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }

      input[type=number] {
        -moz-appearance: textfield; /* Firefox */
      }
    }
  }
}
.compare-responsive-counter {
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #000;
  top: -8px;
  right: 0;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px) {
  .cart-page {
    .cart-product__container {
      position: relative;
      flex-direction: column;
      .left {
        flex-direction: column;
        .name {
          font-size: 20px;
        }
      }
      .right {
        margin-top: 15px;
        text-align: left;
        .delete {
          position: absolute;
          top: 20px;
          right: 20px;
          .ns-icon {
            position: absolute;
            top: 0;
            right: 40px;
          }
        }
        h4 {
          font-size: 15px;
        }
        h3 {
          font-size: 18px;
        }
      }
    }
    it-tabs {
      .it-tabs {
        height: 36px;
        .it-tabs__nav {
          ul {
            li {
              padding: 4px 20px;
              a {
                font-size: 0;
                &::first-letter {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
    .cart-additional {
      margin-top: 0;
    }
    .tab-title {
      display: block;
    }
  }
}

@media (max-width: 500px) {
  .compare-page {
    .compare-product-properties__wrapper {
      flex-direction: row;
      width: 100%;
      min-width: 200px;
    }
    .compare-product-properties {
      min-width: 200px;
    }
    .compare-product-actions {
      .content {
        min-width: 200px;
      }
    }
    .filter-wrapper {
      .filter {
        .label {
          margin-bottom: 4px;
        }
        it-select {
          margin-bottom: 20px;
        }
      }
    }
  }
  .compare-bubble {
    transition: right .3s ease-in-out;
    &.show {
      top: 0;
      right: 0;
      width: 100%;
      z-index: 1004;
      bottom: 0;
    }
  }
}

.garmin-slider {
  .swiper-container {
    margin: 0;
    padding: 0;
  }

  .swiper-pagination {
    display: none;
  }

  .swiper-button-prev, .swiper-button-next {
    color: #fff;
  }

  .text-content {
    position: absolute;
    top: 0;
    left: 120px;
    height: 100%;
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    button.it-button {
      display: flex !important;
    }
  }
}

.garmin-novo { 

  h1 {
    position: relative;
    width: 100%;
    background-color: #000;
    font-size: 18px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;

    &::after {
      content: '';
      position: absolute;
      left: calc(50% - 30px);
      top: 100%;
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-top: 20px solid #000;
      clear: both;
    }
  }
}

.garmin-promo {
  .image {
    max-width: 1024px;
    margin: 0 auto 50px !important;
  }
}

.it-section .it-section__content.category-list {
  grid-template-columns: 100%;
  grid-gap: 30px;
  max-width: 1800px;
  margin: auto;

  &.mobile {
    padding: 0 20px;

    .swiper-button-prev, 
    .swiper-button-next {
      color: #fff;

      &::after {
        font-size: 64px;
      }
    }
  }

  .nudge-left, .nudge-right {
    margin: 0;
  }

  .it-section__item {
    height: 600px;
    background-color: #000;
    border-radius: 0;

    .left, .right {
      justify-content: center;

      p {
        flex: 0 1 100px;
      }
    } 

    it-button button {
      border-radius: 0;
    }
  }
}

.it-section__content.newsletter {
  width: 400px;
  max-width: 95%;
  margin: 80px auto;

  h1 {
    font-size: 28px;
    text-align: center;
  }

  input {
    border: 1px solid #000 !important;
    border-radius: 0 !important;
  }
  .input-button {
    border-radius: 0 !important;
  }
}

.it-section__content-wrapper {
  .it-section__content.slides {
    .it-section__item {
      .text-content {
        h1 {
          font-size: 2.5rem !important;
          font-weight: 400 !important;
          margin: 0;
        }

        it-button {
          margin-top: 20px;
        }        
      }
    }
  }
}


.garmin-novo {
  a {
    color: #313131;
  }
}