figure {
  img {
    width: 100%;
  }
  .image-style-align-right {
    float: right;
    margin-left: 1em;
  }
  .image-style-align-left {
    float: left;
    margin-right: 1em;
  }
  &.image-style-align-right {
    float: right;
    margin-left: 1em;
    + p {
      word-wrap: break-word;
    }
  }
  &.image-style-align-left {
    float: left;
    margin-right: 1em;
    + p {
      word-wrap: break-word;
    }
  }
  &.image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
  }
  &.image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
  }
}
.it-show {
  display: block !important;
}
.it-hide {
  display: none !important;
}
.it-position-relative {
  position: relative;
}
.it-clickable {
  cursor: pointer;
}

.it-breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: $gray60;
  font-weight: 500;
  cursor: pointer;
  .it-icon {
    font-size: 24px;
  }
}

.it-pointer-event-none {
  pointer-events: none;
}

.it-offclick__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .2;
  z-index: 1003;
  cursor: pointer;

  &.transparent {
    opacity: 0;
  }
}

.it-custom-scrollbar {
  scrollbar-color: $gray22 #fff;
  // scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid #C6C6C6;
}

sup, sub { 
  vertical-align: baseline; 
  position: relative; 
  top: -0.4em; 
} 
sub { 
  top: 0.4em; 
}