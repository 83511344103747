
//Colors

$primary: #0041FF;
$primaryDark: #0036D2;
$primary60: #668DFF;
$primary30: #B2C6FF;
$primary20: #CCD9FF;

$secondary: #FF4545;
$secondaryDark: #F21B1B;
$secondary60: #FF8F8F;
$secondary30: #FFC7C7;
$secondary20: #FFDADA;

$black: #000000;
$gray80:#313131;
$gray60: #757575;
$gray22: #C6C6C6;
$gray10: #E3E3E3;
$gray5: #F1F1F1;

$success: #6ED32E;
$danger: #FF2020;
$warning: #ffa931;
$warningLight: #ffeed6;

$primaryText: #000000;

$headerHeight: 70px;

//Border
$borderRadius: 8px;

//Fonts
$icons: it-icons, sans-serif;

$lineHeight: 24px;
$letterSpacing: 4px;

//Transition
$transition: all 200ms ease;

//Grid
$gutter-width: 1280px;
$space-values: 0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52;

//Header / Footer

$header-height: 60px;
$footer-height: 40px;

/* Grid ranges */
$laptop-screen: 1441px;
$tablet-screen: 992px;
$large-phone-screen: 580px;
$phone-screen: 370px;
