@import "variables";

.it-tabs {
  width: 100%;
  height: 48px;
  display: flex;
  border-bottom: 2px solid $primary20;
  &.sticky {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 12;
  }
}

.it-tabs__nav {
  width: 100%;
  display: flex;
  align-items: center;
  ul {
    width: 100%;
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0 12px;
    height: 100%;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      margin: 8px 8px 2px 0;
      border-radius: 8px 8px 0 0;
      padding: 10px 12px;
      font-size: 13px;
      font-family: Prompt, sans-serif;
      cursor: pointer;
      transition: background-color .3s ease-in-out;

      a {
        color: $gray60;
        transition: color .2s ease-in-out;
      }
      &.active {
        background: $primary20;
        a {
          color: $primary;
        }
      }
    }
  }
}

.it-tab__content {
  padding: 0;
  .it-table__wrapper {
    padding: 0 16px;
  }
}

it-tabs {
  &.scrollable-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    it-tab {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      .it-tab__content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        &[hidden] {
          display: none;
        }
      }
    }
  }
}
