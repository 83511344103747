@import "variables";

.it-checkbox {
  height: 36px;
  display: flex;
  align-items: center;
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    &.disabled {
      opacity: 0.6;
    }
  }
  input {
    height: 24px;
    position: relative;
    margin: 0 20px 0 0;
    font-family: it-icons, sans-serif;
    cursor: pointer;
    &::before {
      content: '';
      font-size: 24px;
      height: 24px;
      width: 24px;
      position: absolute;
      left: -1px;
      background: #fff;
      color: $gray22;
      border-radius: 8px;
      border: solid 1px #d8d8d8;
    }
    &.checked {
      &::after {
        content: '\e927';
        font-size: 26px;
        height: 26px;
        width: 26px;
        position: absolute;
        left: -2px;
        color: $primary;
        border-radius: 8px;
        font-family: $icons;
      }
    }
  }
  .it-checkbox__label {
    font-size: 15px;
    line-height: 20px;
    padding-top: 2px;
    color: $gray80;
    font-weight: 400;
  }
}
