@import "variables";

it-accordion {
  display: flex;
}

.it-accordions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.it-accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-bottom: 16px;
  &.active {
    .it-accordion__header {
      // background: $primary;
      .it-accordion__title {
        // color: #fff;
      }
      .it-accordion__actions {
        .it-accordion__action {
          .it-icon {
            // color: #fff;
          }
        }
      }
    }
  }
  .it-accordion__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    transition: background .15s linear;
    cursor: pointer;
    .it-accordion__title {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      color: #000;
    }
    .it-accordion__actions {
      display: flex;
      align-items: center;
      .it-accordion__info {
        span {
          font-size: 13px;
          color: $gray60;
        }
      }
      .it-accordion__action {
        display: flex;
        height: 32px;
        button {
          border: none;
          background: transparent;
          font-size: 32px;
          outline: none;
          color: $gray60;
          cursor: pointer;
        }
      }
    }
  }
  .it-accordion__body {
    padding: 16px 16px 0;
  }
}
