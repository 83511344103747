@import "variables";

ngb-modal-window.modal {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1060;
  background-color: rgba(#000,0.6);
  animation: modalAnimation .3s ease-in;
  .modal-dialog {
    height: 0;
    pointer-events: all;
  }
  it-select {
    .it-select__list-container {
      position: fixed;
      z-index: 2000;
    }
  }
  &.click-disabled {
    pointer-events: none;
  }
}
ngb-modal-backdrop.modal-backdrop {
  position: static;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1062;
}
body {
  &.modal-open {
    position: static;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    infoteam-root {
      pointer-events: none;
    }
  }
}
.it-modal {
  border-radius: $borderRadius;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  overflow: hidden;
  &.small {
    width: 375px;
  }
  &.medium {
    width: 550px;
  }
  &.large {
    width: 960px;
  }
  &.extra-large {
    width: 84vw;
  }
  .it-modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px 20px;
    position: relative;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    font-size: 18px;
    .it-modal__title {
      margin: 0;
    }
    > .it-modal__close {
      background-color: #fff;
      border: 1px solid #fff;
      font-size: 24px;
      @extend .it-center-flex;
      border-radius: 50%;
      cursor: pointer;
      transition: $transition;
      &:before {
        display: block;
        position: absolute;
        top: 8px;
        right: 8px;
        content: '\e96a';
        font-family: it-icons, sans-serif;
      }
    }
  }
  .it-modal__body {
    padding: 0 20px 20px;
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    position: relative;
    .error-text {
      color: $secondary;
      font-size: 13px;
    }
  }
  .it-modal__footer {
    display: flex;
    justify-content: flex-end;
    border-radius: 0 0 8px 8px;
    padding: 20px 20px 20px;
    .it-button {
      button {
        &.primary {
          color: #fff !important;
        }
      }
    }
    button {
      background: transparent;
      border: none;
      outline: none;
      font-size: 15px;
      font-family: 'Prompt', sans-serif;
      color: $primary;
      cursor: pointer;
      &[disabled] {
        opacity: 0.6;
      }
      &.cancel {
        color: $gray60;
      }
      &.danger {
        color: $secondary;
      }
    }
    .it-modal-actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-family: Prompt, sans-serif;
      color: $gray60;
      .it-modal__action-left {
        display: flex;
        align-items: center;
        button {
          &:first-child {
            padding-left: 0;
          }
        }
      }
      .it-modal__action-right {
        button {
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
  &.danger {
    .it-modal__footer {
      button {
        &.primary {
          color: $danger;
        }
      }
    }
  }
  &.warning {
    .it-modal__footer {
      button {
        &.primary {
          color: $warning;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .it-modal {
      &.large {
        border-radius: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        .it-modal__body {
          max-height: 100%;
          flex-grow: 1;
        }
        .it-modal__footer {
          background-color: #fff;
          position: relative;
          width: 100%;
        }
        .it-modal__actions {
          position: relative;
          width: 100%;
        }
      }
    }
}

@media (max-width: 590px) {
  .it-modal {
    &.medium {
      border-radius: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      .it-modal__body {
        max-height: 100%;
        flex-grow: 1;
      }
      .it-modal__footer {
        background-color: #fff;
        position: relative;
        width: 100%;
      }
      .it-modal__actions {
        position: relative;
        width: 100%;
      }
    }
  }
}

@media (max-width: 415px) {
  .it-modal {
      &.small {
        border-radius: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        .it-modal__body {
          max-height: 100%;
          flex-grow: 1;
        }
        .it-modal__footer {
          position: relative;
          width: 100%;
        }
        .it-modal__actions {
          position: relative;
          width: 100%;
        }
      }
    }
}

@keyframes modalAnimation {
  from {
    padding-top: 0;
    background-color: rgba(#000,0);
  }
  to {
    padding-top: 100px;
    background-color: rgba(#000,0.6);
  }
}
