@import "variables";

//Sections
.it-section {
  display: flex;
  position: relative;
  width: 100%;
  // Visibility
  &.hidden {
    display: none;
  }
  &.desktop {
    display: flex;
  }
  &.mobile {
    display: none;
  }


  &:hover {
    .it-section__edit-actions {
      display: flex;
    }
  }
  .it-section-navigation__mobile {
    padding: 0;
    .section-nav__header {
      display: flex;
      justify-content: space-between;
      padding: 16px 20px;
      .ns-icon {
        font-size: 26px;
        cursor: pointer;
      }
    }
    .navigation-wrapper {
      display: flex;
      flex-direction: column;
      &.show {
        top: 0 !important;
      }
      .nav-sub__items {
        top: 58px !important;
      }
    }
  }
  .it-section__edit-actions {
    position: absolute;
    top: -40px;
    left: 0;
    height: 40px;
    border-radius: $borderRadius;
    overflow: hidden;
    box-shadow: 0 2px 8px 0 rgba($color: #000000, $alpha: .2);
    background-color: #fff;
    display: none;
    align-items: center;
    padding: 10px;
    font-family: Prompt, sans-serif;
    z-index: 2;
    .spacer {
      height: 100%;
      width: 1px;
      background-color: $gray22;
      margin: 4px 12px;
    }
    .action {
      display: flex;
      align-items: center;
      &.double {
        flex-direction: column;
        &:hover {
          color: $gray80;
        }
        .it-icon {
          &:hover {
            color: $primary;
          }
        }
      }
      .it-icon {
        font-size: 20px;
        margin-right: 8px;
      }
      &.delete {
        &:hover {
          color: $secondary;
        }
      }
      &:hover {
        color: $primary;
        cursor: pointer;
      }
    }
  }
  .it-section__content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    &.text-center {
      text-align: center;
    }
    &.full-width {
      position: relative;
      width: 100vw;
      margin: -25px 0 -30px -50vw;
      left: 50%;
    }
    &.full-width.preview {
      position: relative;
      width: 100%;
      margin: 0;
      left: 0;
    }
    &.dark {
      background-color: #000 !important;
      color: #fff !important;

      h1, h2, h3, h4 {
        color: #fff !important;
      }
    }
    &.light {
      background-color: #fff !important;
      color: #000 !important;

      h1, h2, h3, h4 {
        color: #000 !important;
      }
    }
    &.huge {
      font-size: 60px;
      line-height: 106px;

      h1, h2, h3, h4 {
        font-size: 60px;
        line-height: 130px;
      }
    }
    .section-title {
      text-align: center;
    }
  }
  .it-section__content {
    width: 100%;
    &.product-list {
      it-slides {
        display: block;
      }
      .swiper-container {
        padding: 8px;
      }
      .it-section__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 15px 20px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        img {
          width: 150px;
        }
        .info {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          .product-title {
            font-family: Manrope, sans-serif;
            font-size: 17px;
            font-weight: 400;
            margin-bottom: 4px;
            color: #313131;
            width: 100%;
            margin: 0 0 6px 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .price {
            font-family: Manrope, sans-serif;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 4px;
            color: #1073E5;
            &.discount {
              color: #E21800;
            }
          }
          .old-price {
            font-family: Manrope, sans-serif;
            font-size: 15px;
            font-weight: 400;
            text-decoration: line-through;
            color: #7e7e7e;
          }
        }
      }

      .see-all {
        margin-top: 16px;
        text-align: center;

        it-button {
          display: inline-block;
          width: 200px;
        }
      }
    }
    &.all-products {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .it-section__item {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        it-button {
          width: 50%;
          max-width: 50%;
          text-align: center;
          display: flex;
          justify-content: center;
          button {
            width: 50%;
            max-width: 50%;
            height: 48px;
            border-radius: 24px;
            span {
              font-size: 17px;
            }
          }
        }
      }
    }
    &.newsletter {
      display: flex;
      flex-direction: column;
      .it-section__item {
        &:first-child {
          margin-bottom: 20px;
        }
        it-input {
          .it-input__label {
            display: none;
          }
          .it-input {
            input {
              height: 46px;
            }
          }
        }
      }
    }
    &.category-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(550px, 625px));
      grid-gap: 16px;
      justify-content: center;
      min-height: 250px;

      .nudge-left {
        margin-left: -30px;
        margin-right: 30px;
      }
      .nudge-right {
        margin-left: 30px;
        margin-right: -30px;
      }

      &.desktop { 
        display: grid;
      }
      &.mobile { 
        display: none;

        .swiper-container {
          margin: 0 -20px !important;
        }

        .it-section__item {
          position: relative;
          width: 100%;
          border-radius: 0;
          flex-direction: column !important;
          align-items: flex-start;
          padding: 20px;
          margin-bottom: 20px;

          img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: 0;
            filter: brightness(50%);
          }

          h1 {
            z-index: 1;
            color: #fff;
            font-size: 20px;
            text-transform: uppercase;
            text-shadow: 1px 1px #000000a1;
            margin-bottom: 10px;
          }

          p {
            z-index: 1;
            color: #fff;
          }
        }
      }

      .it-section__item {
        height: 316px;
        display: flex;
        align-items: center;
        color: #fff;
        background-color: #2E2E2E;
        border-radius: $borderRadius;
        overflow: hidden;
        &:nth-child(odd) {
          flex-direction: row-reverse;
        }
        .left {
          display: flex;
          flex-direction: column;
          padding: 30px;
          height: 100%;
          width: 50%;
          overflow: hidden;
          h1 {
            color: #fff;
            text-transform: uppercase;
            margin-bottom: 15px;
          }
          p {
            margin-bottom: 30px;
            flex: 1;
            overflow: auto;
          }
        }
        .right {
          height: 100%;
          width: 50%;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        it-button {
          button {
            border-radius: 20px;
            background-color: transparent;
            border: 2px solid #fff;
            span {
              font-size: 15px;
            }
          }
        }
      }
    }
    &.slides {
      text-align: center;
      .it-section__item {
        it-slides {
          display: block;
        }
        .image {
          position: relative;
          img {
            display: block;
            width: auto;
            // min-height: 200px;
            object-fit: cover;
          }
          .it-icon {
            font-size: 68px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: #fff;
            opacity: 80%;
            &.arrow-left {
              left: 0;
            }
            &.arrow-right {
              right: 0;
            }
          }
        }
        .text-content {
          background-color: #000;
          color: #fff;
          padding: 32px 16px;
          font-size: 18px;
          h1 {
            color: #fff;
            font-size: 28px;
          }
          p {
            margin: 12px 0 0;
            font-size: .8em;
          }
          it-button {
            button {
              border: solid 1px #fff;
              background-color: transparent;
              height: 46px;
              border-radius: 0;
              width: max-content;
              width: -moz-max-content;
              span {
                font-size: 16px;
              }
            }
          }
          &.dark {
            color: #000;

            h1 {
              color: #000;
            }

            it-button {
              button {
                border: solid 1px #000;
                color: #000;
              }
            }
          }
        }
        it-slide {
          .slide-wrapper {
            flex-direction: column;
            height: 100%;
            img {
              // min-height: 200px;
              width: auto;
              object-fit: contain;
            }
          }
        }
      }
    }
    &.custom {
      display: grid;
      grid-gap: 16px;
      justify-content: center;
      .it-section__item {
        min-height: 276px;
        max-height: 276px;
        padding: 12px;
        border: 1px solid $gray10;
        overflow: auto;
        cursor: pointer;

        transition: border 500ms linear;

        &:hover {
          border: 1px solid $gray60;
        }
        &.active {
          padding: 0px !important;
          z-index: 1004;
          cursor: inherit;
        }
        &.loading {
          position: relative;
          pointer-events: none;

          &::after {
            content: '';
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
          }
        }
      }

      ul {
        margin-block-start: 1em;
        padding-inline-start: 40px;
      }
      figure {
        margin: 0;
        .embed-container {
          margin: 0;
          position: relative;
          width: 100%;
          height: 400px;
          iframe {
            width: 100%;
            height: 100%;
          }
        }

        img {
          display: block;
          max-width: 75%;
          margin: auto;
        }
      }
    }
    &.promo {
      .it-section__item {
        position: relative;
      }
     
      img {
        width: 100%;
        max-width: 100%;
      }
    
      .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 40px;
        color: #fff;
        display: flex;

        h1, h2, h3, h4 {
          color: inherit;
        }

        h2, h3 {
          font-size: 60px;
          margin-bottom: 30px;
        }
        h4 {
          font-size: 30px;
          line-height: 38px;
        }

        &.top-left {
          align-items: flex-start;
          justify-content: flex-start;
          text-align: left;
        }
        &.top-center {
          align-items: flex-start;
          justify-content: center;
          text-align: center;
        }
        &.top-right {
          align-items: flex-start;
          justify-content: flex-end;
          text-align: right;
        }
    
        &.middle-left {
          align-items: center;
          justify-content: flex-start;
          text-align: left;
        }
        &.middle-center {
          align-items: center;
          justify-content: center;
          text-align: center;
        }
        &.middle-right {
          align-items: center;
          justify-content: flex-end;
          text-align: right;
        }
    
        &.bottom-left {
          align-items: flex-end;
          justify-content: flex-start;
          text-align: left;
        }
        &.bottom-center {
          align-items: flex-end;
          justify-content: center;
          text-align: center;
          
        }
        &.bottom-right {
          align-items: flex-end;
          justify-content: flex-end;
          text-align: right;
        }
      }
    
    }
  }
}

.it-slide__container {
  border-radius: $borderRadius;
  overflow: hidden;
  padding: 12px;
  box-shadow: 0 1px 4px 0 rgba($color: #000000, $alpha: 0.25);
  position: relative;
  margin-bottom: 8px;
  .action-remove {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 24px;
    color: $gray60;
    cursor: pointer;
    &:hover {
      color: $secondary;
    }
  }
}

.it-sections__wrapper {
  .it-section {
    &:first-child {
      .it-section__edit-actions {
        .double {
          .arrow-up {
            display: none;
          }
        }
      }
    }
    &:last-child {
      .it-section__edit-actions {
        .double {
          .arrow-down {
            display: none;
          }
        }
      }
    }
  }
}

.it-page-builder__wrapper {
  background-color: #fff;
  width: 100%;
  min-height: 300px;
  padding: 50px 16px;
  position: relative;
  overflow: auto;
  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 60px 16px 16px;
    z-index: 1054;
  }
  .exit-full-screen {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .it-builder__section-placeholder {
    height: 120px;
    border: 1px dashed $gray60;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $gray60;
    cursor: pointer;
    transition: max-height .1s ease-in-out, color .2s ease-in-out;
    &:hover {
      border-width: 2px;
      border-color: $primary;
      border-style: solid;
      color: $primary;
    }
    .text {
      font-family: Prompt, sans-serif;
      font-size: 13px;
    }

    .it-icon {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }

  .it-section__content-wrapper {
    padding: 10px 0;
  }
}

.it-section-navigation__mobile {
  .navigation-wrapper {
    position: fixed;
    top: 120px;
    left: 0;
    display: flex;
    flex: 1 1 auto;
    height: 0;
    width: 100%;
    overflow: auto;
    background-color: #fff;
    transition: height .3s ease-in-out;
    z-index: 1004;
    &.top {
      top: 141px;
    }
    &.show {
      height: 100%;
      padding-bottom: 120px;
    }
    ul {
      display: flex;
      flex-direction: column;
      height: fit-content;
      width: 100%;
      padding: 0 16px;
      .add {
        font-size: 18px;
      }
      .nav-item {
        display: flex;
        flex-direction: column;
        font-family: Rubik, sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
        &.sub {
          margin: 0;
          .title {
            margin: 0;
            padding: 15px 0;
            color: #313131;
          }
        }
        &.login {
          margin: 25px 0;
          border-top: 1px solid $gray22;
          border-bottom: 1px solid $gray22;
          .title {
            justify-content: initial;
            padding: 16px 0;
          }
        }
        &.categories {
          .nav-sub__items {
            position: initial;
            padding: 16px 0 0 0;
            li {
              .title {
                padding: 0;
                margin-bottom: 18px;
              }
              &:last-of-type {
                .title {
                  margin-bottom: 30px;
                }
              }
            }
            .nav-sub-sub__items {
              display: flex;
              flex-direction: column;
              flex: 1 1 auto;
              height: 0;
              width: 100%;
              overflow: hidden;
              background-color: #fff;
              transition: height .3s ease-in-out;
              &.show {
                height: auto;
                padding: 0 20px;
              }
              li {
                font-family: Rubik, sans-serif;
                font-size: 17px;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 15px;
                color: #313131;
              }
            }
          }
        }
        .title {
          display: flex;
          justify-content: space-between;
          margin: 9px 0;
          &.active {
            width: fit-content;
            padding-bottom: 2px;
            border-bottom: 2px solid #1073E5;
          }
          &.contact {
            margin: 0 0 15px 0;
            color: #313131;
          }
          &.bold {
            font-weight: 600;
          }
          .arrow-right, .arrow-up, .arrow-down {
            font-size: 24px;
          }
        }
        .nav-sub__items {
          position: fixed;
          top: 120px;
          right: -1400px;
          padding-bottom: 125px;
          overflow: auto;
          background-color: #fff;
          transition: right .3s ease-in-out;
          cursor: pointer;
          &.show {
            height: 100vh;
            right: 0;
          }
          .go-back {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            font-size: 15px;
            color: #787878;
            .arrow-left {
              font-size: 24px;
              color: #797979;
            }
          }
          .caption {
            font-weight: 600;
            margin-bottom: 10px;
            color: #313131;
          }
          .nav-sub-sub__items {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            height: 0;
            width: 100%;
            overflow: hidden;
            background-color: #fff;
            transition: height .3s ease-in-out;
            &.show {
              height: auto;
              padding: 0 20px;
            }
            li {
              font-family: Rubik, sans-serif;
              font-size: 17px;
              font-weight: 400;
              line-height: 24px;
              margin-bottom: 15px;
              color: #313131;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1380px) {
  .it-section {
  }
}
@media (max-width: 992px) {
  .it-section {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: flex;
    }

    .it-section__content {
      &.all-products {
        .it-section__item {
          it-button {
            width: 100%;
            max-width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            button {
              width: 100%;
              max-width: 100% !important;
              height: 48px;
              border-radius: 24px;
              span {
                font-size: 17px;
              }
            }
          }
        }
      }
      &.custom {
        grid-template-columns: auto !important;
      }
    }
  }
}

@media (max-width: 640px) {
  .category-list.desktop { 
    display: none !important;
  }
  .category-list.mobile { 
    display: block !important;
  }

  .it-section .it-section__content.promo .content h2, 
  .it-section .it-section__content.promo .content h3 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .it-section .it-section__content.promo .content h4 {
    font-size: 18px;
    line-height: 24px; 
  }

  .it-section .it-section__content.promo img {
    min-height: 400px;
    object-fit: cover;
  }
}
