@import "variables";

it-button {
  &[disabled] {
    pointer-events: none;
  }
}

.it-button {
  display: inline-flex;
  align-items: center;
  height: 36px;
  padding: 6px 16px;
  background: transparent;
  border-radius: 8px;
  border: solid 2px #ffffff;
  color: #fff;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  pointer-events: auto;
  z-index: 1;
  span {
    width: 100%;
    font-family: Prompt, sans-serif;
    font-size: 13px;
    text-align: center;
    vertical-align: middle;
    line-height: 0;
    white-space: nowrap;
  }
  &:hover {
    transition: background-color 0.5s, border-color 0.5s, color 0.5s;
  }
  &.small {
    height: 36px;
    line-height: 17px;
    span {
      font-size: 13px;
    }
    &.it-icon {
      &:before {
        font-size: 13px;
      }
    }
  }
  &.full {
    width: 100%;
  }
  &.half {
    width: 50%;
  }
  &.primary {
    background-color: $primary;
    border: solid 2px $primary;
    color: #fff;
    &[disabled] {
      background-color: $primary;
      opacity: 0.6;
    }
    &.active {
      background-color: $primaryDark;
      border: solid 2px $primaryDark;
    }
    &:hover {
      background-color: $primaryDark;
      border: solid 2px $primaryDark;
    }
    &.transparent {
      background-color: transparent;
      border: none !important;
    }
  }
  &.primary20 {
    background-color: $primary20;
    border: solid 2px $primary20;
    color: $primary;
    &[disabled] {
      background-color: $gray22;
      opacity: 0.6;
    }
    &:hover {
      background-color: $primary30;
      border: solid 2px $primary30;
    }
  }
  &.secondary {
    border: solid 2px $primary20;
    background-color: $primary20;
    span {
      color: $primary;
    }
    &[disabled] {
      border-color: $primary;
      opacity: 0.6;
    }
    &.active {
      .it-button__text {
        color: $primaryDark;
      }
      border-color: $primaryDark;
    }
    &:hover {
      .it-button__text, span, &:before {
        color: $primaryDark !important;
      }
      border-color: $primaryDark;
    }
  }
  &.secondary20 {
    background-color: $secondary20;
    border: solid 2px $secondary20;
    color: $secondary;
    &[disabled] {
      background-color: $gray22;
      opacity: 0.6;
    }
    &:hover {
      border: solid 2px $secondary30;
    }
  }
  &.outline {
    background-color: #fff;
    border: solid 2px $gray22;
    span {
      color: $gray60;
    }
    &[disabled] {
      background-color: #fff;
      border-color: $gray22;
      opacity: 0.6;
    }
    &.active {
      border-color: $gray80;
    }
    &:hover {
      .it-button__text, span, &:before {
        color: $gray80 !important;
      }
      border-color: $gray80;
    }
  }
  &.white {
    background-color: #fff;
    border: solid 2px transparent;
    span {
      color: $primary;
    }
    &[disabled] {
      opacity: 0.6;
    }
    &.active {
      .it-button__text {
        color: $primaryDark;
      }
    }
    &:hover {
      .it-button__text, span, &:before {
        color: $primaryDark !important;
      }
    }
  }
  &.it-icon {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    line-height: 24px;
    &:before {
      padding-right: 4px;
      z-index: 1;
    }
    &.white, &.secondary {
      &:before {
        color: $primary;
      }
      &.active {
        &:before {
          color: $primaryDark;
        }
      }
    }
    &.outline {
      &:before {
        color: $gray60;
      }
      &.active {
        &:before {
          color: $gray80;
        }
      }
    }
  }
  &.only-icon {
    &.it-icon {
      &:before {
        padding-right: 0;
      }
    }
  }
  &[disabled] {
    pointer-events: none;
  }
  &.it-button__loading {
    position: relative;
    > span {
      color: transparent !important;
      visibility: hidden;
    }
    pointer-events: none;
    &.it-icon {
      &:before {
        display: none;
      }
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      content: '\e90c';
      color: #fff;
      font: normal 400 1.275em/1 it-icons, sans-serif;
      animation: rotating 2000ms infinite linear;
    }
    &.outline {
      &:after {
        color: $gray60;
      }
    }
    &.white {
      &:after {
        color: $primary;
      }
    }
  }
  &.icon-right {
    &.it-icon {
      &:before {
        position: absolute;
        right: 8px;
        padding-left: 4px;
        z-index: 1;
      }
    }
  }
}

/* Loading animation on button */
@keyframes rotating {
  0% {
    transform: rotate3d(0,0,1,0);
  }
  100% {
    transform: rotate3d(0,0,1,360deg);
  }
}
